import React, { Component, ReactNode } from 'react';
import { ErrorBoundaryStyles } from '@/Modules/App/Components/ErrorBundary/ErrorBoundary.styles';
import { UserService } from '@/Modules/App/Services/User/User.service';

interface ErrorBoundaryProps
{
  children: ReactNode;
}

interface ErrorBoundaryState
{
  hasError: boolean;
  errorInfo: string | null;
  counter: number;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState>
{
  intervalId: NodeJS.Timeout | null = null;

  constructor(props: ErrorBoundaryProps)
  {
    super(props);
    this.state = {
      hasError: false,
      errorInfo: null,
      counter: 3,
    };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState
  {
    return { hasError: true, errorInfo: error.message, counter: 3 };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void
  {
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
  }

  componentDidUpdate(): void
  {
    if (this.state.hasError && !this.intervalId) {
      this.intervalId = setInterval(() =>
      {
        this.setState((prevState) => ({ counter: prevState.counter - 1 }), () =>
        {
          if (this.state.counter === 0) {
            clearInterval(this.intervalId!);
            this.intervalId = null;
            this.setState({ hasError: false });
          }
        });
      }, 1000);
    }
  }

  componentWillUnmount(): void
  {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  render()
  {
    if (this.state.counter === 0) {
      window.location.href = (UserService.isAdmin()||UserService.isSuperAdmin()) ? '/admin' : '/';
      return;
    }

    if (this.state.hasError) {
      return (
        <div style={ ErrorBoundaryStyles.container }>
          <div style={ ErrorBoundaryStyles.card }>
            <h1 style={ ErrorBoundaryStyles.title }>Une erreur est survenu !</h1>
            <p style={ ErrorBoundaryStyles.message }>
              Merci de contacter le support.
            </p>
            { this.state.errorInfo&&(
              <p style={ ErrorBoundaryStyles.errorDetails }>
                <strong>Détails :</strong> { this.state.errorInfo }
              </p>
            ) }
            <p style={ ErrorBoundaryStyles.counter }>
              Redirection vers l'accueil dans { this.state.counter } seconde
              { this.state.counter > 1 ? 's' : '' }...
            </p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
