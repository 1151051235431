import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { colors, hexToRgba } from '@/Modules/App/Style/Variables/Colors.styles';
import { themeColors } from '@/Modules/App/Style/Base/Theme.styles';

export const OffcanvaStyles = {
	container: {
		position: 'fixed',
		width: 850,
		height: `calc(100vh - 50px)`,
		top: '94px',
		right: 0,
		backgroundColor: CssVariableEnum['--color-white'],
		borderLeft: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
		borderBottom: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
		boxShadow: 'rgba(0, 0, 0, 0.2) 0px 20px 30px',
		transition: 'width 270ms ease',
		zIndex: 1000,
		overflowX: 'hidden'
	} as CSSProperties,
	devContainer: {
		position: 'fixed',
		width: 850,
		height: `calc(100vh - 50px)`,
		top: '94px',
		right: 0,
		backgroundColor: themeColors.darkBackground,
		borderLeft: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
		borderBottom: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
		boxShadow: 'rgba(0, 0, 0, 0.2) 0px 20px 30px',
		transition: 'width 270ms ease',
		zIndex: 1000,
		overflowX: 'hidden'
	} as CSSProperties,

	backdrop: (isOpen: boolean) => ({
		position: 'absolute',
		zIndex: 1500,
		height: '100vh',
		width: '100vw',
	}) as CSSProperties,

	content: {
		padding: '0 30px 30px 30px',
		position: 'relative',
		top: 70,
		minHeight: 'calc(100% + 100px)',
		overflowY: 'scroll'
	} as CSSProperties,

	overlay: {
		position: 'absolute',
		zIndex: 200,
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: hexToRgba(colors.white, 0.4),
		borderRadius: 10,
	} as CSSProperties,

	loading: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: 'rgba(255, 255, 255, 0.7)',
		color: CssVariableEnum['--color-grey-900'],
		borderRadius: 20,
		zIndex: 1510
	} as CSSProperties,
	devLoading: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: themeColors.darkBackground,
		color: colors.success50,
		zIndex: 1510
	} as CSSProperties,
} as const;