import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
	LegalNoticePriceDetailsDefault,
	LegalNoticePriceDetailsProps,
	LegalNoticePriceDetailsState
} from './LegalNoticePriceDetails.interface';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';
import { NewspaperTypeEnum } from '@/Enum/NewspaperTypeEnum';
import { stripHtmlTags } from '@/Utils/StripHtmlTags';
import { LegalNoticePriceService } from '@/Service/LegalNoticePriceService';
import { UserService } from '@/Modules/App/Services/User/User.service';
import { TableHeadColumnsInterface } from '@/Modules/App/Components/Library/Table/Table.interface';
import { ApiPublicService } from '@/Service/Api/ApiPublicService';
import { PricingLineInterface } from '@/Modules/Pricing/Interface/PricingLineInterface';
import LocalStorageService from '@/Service/Common/LocalStorageService';

const initState: LegalNoticePriceDetailsState = LegalNoticePriceDetailsDefault();

export class LegalNoticePriceDetailsService extends BaseComponentService<LegalNoticePriceDetailsProps, LegalNoticePriceDetailsState>
{
	private apiPublicService: ApiPublicService = new ApiPublicService();
	private legalNoticePriceService:  LegalNoticePriceService;
	constructor()
	{
		super({} as LegalNoticePriceDetailsProps, initState);

		// Services
		this.legalNoticePriceService = new LegalNoticePriceService(UserService.isSuperAdmin() || UserService.isAdmin());
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		await this.updateState();
		this.setList();
		await this.handlePrintPrice();
	}

	/**
	 * Set List state to Paginate Interface
	 */
	setList(): void
	{
		this.setState({
			priceList: this.tableDataPrices(this.detailedPrices())
		}, () => this.setState({ isLoading: false }));
	}

	/**
	 * Update State
	 * @return Promise<void>
	 * @public
	 */
	async updateState(): Promise<void>
	{
		if (this.state.isPriceLoaded) {
			return;
		}

		// Init vars price
		const isFixedPrice = this.legalNoticePriceService.isFixedPrice(
			this.props.selectedDepartment,
			this.props.selectedCategory
		);
		const annexPrice = parseFloat(
			this.legalNoticePriceService.getAnnexPrice(
				this.props.selectedDepartment,
				this.props.selectedCategory,
				isFixedPrice
			).toFixed(2)
		);
		const printPrice = await this.legalNoticePriceService.getPrintPriceFull();
		const getExternPrice = await this.legalNoticePriceService.getExternPriceFull();
		const shippingCostPrice = await this.legalNoticePriceService.getShippingCostPriceFull(this.props.legalNotice.numberOfCopies);
		const getBodaccPrice = this.props.legalNotice.option.isBodacc ? await this.legalNoticePriceService.getBodacPriceFull() : 0;
		const externPrice = (
			(this.props.selectedNewspaper?.editorial && this.props.selectedNewspaper.editorial !== 'heraultjuridique') &&
			(this.props.selectedNewspaper?.name && this.props.selectedNewspaper.name !== 'L\'Echo du Languedoc')
		) ? (LocalStorageService.getAuthCompanyId() !== 4) ? getExternPrice : 0 : 0;

		this.setState({
			isFixedPrice,
			annexPrice,
			shippingCostPrice,
			printPrice,
			bodaccPrice: getBodaccPrice as PricingLineInterface,
			externPrice: externPrice as PricingLineInterface,
			isPriceLoaded: true
		});
	}

	/**
	 * Build Table Columns
	 * @return TableHeadColumnsInterface[]
	 * @public
	 */
	buildColumnHeader(): TableHeadColumnsInterface[]
	{
		return [
			{
				title: 'Libellé',
				tag: 'label',
				columnWidth: 400,
				textAlign: 'left',
				fontWeight: 500,
				keyType: 'string',
				keyTag: ['label']
			},
			{
				title: 'Prix',
				tag: '',
				columnWidth: 50,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'string',
				keyTag: ['priceUnit']
			},
			{
				title: 'Qte',
				tag: '',
				columnWidth: 50,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'string',
				keyTag: ['quantity']
			},
			{
				title: 'Tva',
				tag: '',
				columnWidth: 50,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'string',
				keyTag: ['vatRate']
			},
			{
				title: 'Montant',
				tag: '',
				columnWidth: 50,
				textAlign: 'right',
				fontWeight: 600,
				keyType: 'string',
				keyTag: ['priceInclVat']
			}
		];
	}

	/**
	 * This method is use to NOT add extern price if the newspaper is interned
	 */
	async handlePrintPrice(): Promise<void>
	{
		if (this.props.selectedNewspaper?.editorial && this.props.selectedNewspaper.editorial !== 'heraultjuridique') {
			const getNewspaper = await this.apiPublicService.getNewspaper(
				this.props.selectedNewspaper.id,
			);

			this.setState({ externPrintPrice: getNewspaper.receiptPrice });
		}
	}

	/**
	 * Return details Price
	 * @private
	 */
	private detailedPrices(): any
	{
		const calculatedPrice = this.calculatedPrice();

		return {
			legalNoticePrice: {
				priceUnit: this.state.annexPrice.toFixed(2),
				priceInclVat: (this.state.annexPrice * (calculatedPrice.nbCharacter ?? 1) * 1.2).toFixed(2),
				priceExclVat: (this.state.annexPrice * (calculatedPrice.nbCharacter ?? 1)).toFixed(2),
				nbCharacters: calculatedPrice.nbCharacter,
				vatRate: 20
			},
			printPrice: {
				priceUnit: this.state.externPrintPrice !== null
					? this.state.externPrintPrice.toFixed(2)
					: (this.state.printPrice ? this.state.printPrice.price.toFixed(2) : '0.00'),
				priceInclVat: this.state.externPrintPrice !== null
					? (this.state.externPrintPrice * 1.021).toFixed(2)
					: (this.state.printPrice ? (this.state.printPrice.price * 1.021).toFixed(2) : '0.00'),
				priceExclVat: this.state.externPrintPrice !== null
					? this.state.externPrintPrice.toFixed(2)
					: (this.state.printPrice ? this.state.printPrice.price.toFixed(2) : '0.00'),
				vatRate: this.state.externPrintPrice !== null
					? 2.1
					: (this.state.printPrice ? this.state.printPrice.vatRate : 0)
			},
			shippingCostPrice: {
				priceUnit: this.state.shippingCostPrice ? this.state.shippingCostPrice.price.toFixed(2) : '0.00',
				priceInclVat: (this.state.shippingCostPrice && this.props.legalNotice.numberOfCopies !== 0)
					? (this.state.shippingCostPrice.price * 1.2).toFixed(2)
					: 0,
				priceExclVat: this.state.shippingCostPrice ? this.state.shippingCostPrice.price.toFixed(2) : '0.00',
				vatRate: this.state.shippingCostPrice ? this.state.shippingCostPrice.vatRate : 0,
			},
			bodaccPrice: {
				priceUnit: this.state.bodaccPrice ? this.state.bodaccPrice.price.toFixed(2) : '0.00',
				priceInclVat: this.state.bodaccPrice ? (this.state.bodaccPrice.price * 1.2).toFixed(2) : '0.00',
				priceExclVat: this.state.bodaccPrice ? this.state.bodaccPrice.price.toFixed(2) : '0.00',
				vatRate: this.state.bodaccPrice ? this.state.bodaccPrice.vatRate : 0
			},
			externPrice: {
				priceUnit: this.state.externPrice ? this.state.externPrice.price.toFixed(2) : '0.00',
				priceInclVat: this.state.externPrice ? (this.state.externPrice.price * 1.2).toFixed(2) : '0.00',
				priceExclVat: this.state.externPrice ? this.state.externPrice.price.toFixed(2) : '0.00',
				vatRate: this.state.externPrice ? this.state.externPrice.vatRate : 0
			}
		};
	}

	/**
	 * Build table list LegalNotice Price
	 * @param priceDetails
	 * @private
	 */
	private tableDataPrices(priceDetails: any): PaginateInterface
	{
		const priceItems: any[] = [{
			label: 'Annonce légale',
			priceUnit: priceDetails.legalNoticePrice.priceUnit,
			priceInclVat: priceDetails.legalNoticePrice.priceInclVat,
			priceExclVat: priceDetails.legalNoticePrice.priceExclVat,
			quantity: (priceDetails.legalNoticePrice.nbCharacters) ? priceDetails.legalNoticePrice.nbCharacters : 1,
			vatRate: priceDetails.legalNoticePrice.vatRate,
		}];

		if (this.props.legalNotice.option.publishType.toString() === NewspaperTypeEnum.PAPER.value
			&& this.props.legalNotice.numberOfCopies !== 0
		) {
			priceItems.push({
					label: 'Journal Papier',
					priceUnit: priceDetails.printPrice.priceUnit,
					priceInclVat: priceDetails.printPrice.priceInclVat * this.props.legalNotice.numberOfCopies,
					priceExclVat: priceDetails.printPrice.priceExclVat,
					vatRate: priceDetails.printPrice.vatRate,
					quantity: this.props.legalNotice.numberOfCopies,
				},
				{
					label: 'Frais d\'envoi',
					priceUnit: priceDetails.shippingCostPrice.priceUnit,
					priceInclVat: priceDetails.shippingCostPrice.priceInclVat,
					priceExclVat: priceDetails.shippingCostPrice.priceExclVat,
					vatRate: priceDetails.shippingCostPrice.vatRate,
					quantity: null,
				});
		}

		if (this.state.externPrice) {
			priceItems.push(
				{
					label: 'Frais de débours et transmission',
					priceUnit: priceDetails.externPrice.priceUnit,
					priceInclVat: priceDetails.externPrice.priceInclVat,
					priceExclVat: priceDetails.externPrice.priceExclVat,
					vatRate: priceDetails.externPrice.vatRate,
					quantity: null,
				}
			);
		}

		if (this.props.legalNotice.option.isBodacc) {
			priceItems.push(
				{
					label: 'Bodacc',
					priceUnit: priceDetails.bodaccPrice.priceUnit,
					priceInclVat: priceDetails.bodaccPrice.priceInclVat,
					priceExclVat: priceDetails.bodaccPrice.priceExclVat,
					vatRate: priceDetails.bodaccPrice.vatRate,
					quantity: null,
				}
			);
		}

		return {
			items: priceItems,
			itemsPerPage: 10,
			totalCount: 20,
			totalPages: 1
		};
	}

	/**
	 * Handle calculate Price
	 * @private
	 */
	private calculatedPrice(): { priceInclVat: number, priceExclVat: number, nbCharacter?: number }
	{
		const VAT_RATE = 0.2;
		const PRINT_VAT_RATE = 0.021;

		const nbCharacters = this.state.isFixedPrice
			? 1
			: (
				stripHtmlTags(this.props.legalNotice.content).length +
				stripHtmlTags(this.props.legalNotice.title).length +
				stripHtmlTags(this.props.legalNotice.signature).length //+
				//(this.props.isHeaderCharacterCount || 0)
			)
		;

		const headerCount = this.state.isFixedPrice
			? 0
			: (this.props.legalNotice.option.isHeader
					? this.legalNoticePriceService.countTempHeaderContent(this.props.legalNotice.consumer)
					: 0
			);

		const totalNbCharacters = nbCharacters + headerCount;
		const legalNoticePrice = this.state.annexPrice * totalNbCharacters;

		const printPrice = this.props.legalNotice.option.publishType.toString() === NewspaperTypeEnum.PAPER.value
			? (this.state.externPrintPrice !== null
				? this.state.externPrintPrice
				: (this.state.printPrice?.price || 0))
			: 0
		;

		const totalPrintPrice = printPrice * this.props.legalNotice.numberOfCopies;
		const shippingCostPrice = (this.props.legalNotice.option.publishType.toString() === NewspaperTypeEnum.PAPER.value && this.props.legalNotice.numberOfCopies !== 0)
			? (this.state.shippingCostPrice?.price || 0)
			: 0
		;

		const bodaccPrice = this.props.legalNotice.option.isBodacc
			? this.state.bodaccPrice?.price || 0
			: 0
		;

		const getExternPrice = (this.state.externPrice) ? this.state.externPrice.price : 0;

		const legalNoticePriceIncVat = legalNoticePrice * (1 + VAT_RATE);
		const printPriceIncVat = totalPrintPrice * (1 + PRINT_VAT_RATE);
		const shippingCostPriceIncVat = shippingCostPrice * (1 + VAT_RATE);
		const bodaccPriceIncVat = bodaccPrice * (1 + VAT_RATE);
		const externPriceIncVat = getExternPrice * (1 + VAT_RATE);

		const totalPriceExclVat = legalNoticePrice + totalPrintPrice + shippingCostPrice + bodaccPrice + getExternPrice;
		const totalPriceInclVat = legalNoticePriceIncVat + printPriceIncVat + shippingCostPriceIncVat + bodaccPriceIncVat + externPriceIncVat;

		return {
			priceInclVat: parseFloat(totalPriceInclVat.toFixed(2)),
			priceExclVat: parseFloat(totalPriceExclVat.toFixed(2)),
			nbCharacter: this.state.isFixedPrice ? undefined : totalNbCharacters
		};
	}
}