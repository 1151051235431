import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import LayoutAdmin from '@/Modules/App/Components/Layout/LayoutAdmin';
import LayoutAuth from '@/Modules/App/Components/Layout/LayoutAuth';
import LayoutPublic from '@/Modules/App/Components/Layout/LayoutPublic';
import { LayoutWrapperProps } from '@/Modules/App/Type/LayoutWrapperTypes';
import LayoutDeveloper from '@/Modules/App/Components/Layout/LayoutDeveloper';

const LayoutWrapper: React.FC<LayoutWrapperProps> = ({ layoutType, ...props }) =>
{
	const navigate = useNavigate();

	switch (layoutType) {
		case 'developer':
			return <LayoutDeveloper { ...props } />;
		case 'admin':
			return<LayoutAdmin { ...props } />;
		case 'auth':
			return <LayoutAuth navigation={ navigate } { ...props } />;
		case 'public':
			return <LayoutPublic navigation={ navigate } { ...props } />;
		default:
			return <LayoutAdmin { ...props } />;
	}
};

export default LayoutWrapper;
