import React, { ReactElement } from 'react';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import NotificationView from '@/Modules/Notifications/View/NotificationView';
import SidebarMenu from '@/Modules/App/Components/Menu/SidebarMenu/SidebarMenu';
import { LayoutProps, LayoutState } from '@/Modules/App/Components/Layout/Layout.interface';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import { LayoutStyles } from '@/Modules/App/Components/Layout/Layout.styles';
import TopBar from '@/Modules/App/Components/Menu/TopBarMenu/TopBar';
import AppService from '@/Service/Common/AppService';
import Sandbox from '@/Modules/App/Components/Sandbox/Sandbox';
import Content from '@/Modules/App/Components/Content/Content';
import { Badge } from 'react-bootstrap';

class LayoutDeveloper extends React.Component<LayoutProps, LayoutState>
{

	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();
	}

	render(): ReactElement
	{
		if (!this.props.authContext?.user) {
			return <LoaderComponent height={ '100vh' }/>;
		}

		return (
			<>
				<div style={ LayoutStyles.devContainer }>
					{ this.state.isSandBoxActive &&
						<Sandbox
							title={ 'Mode develop' }
							content={ 'Vous êtes actuellement sur un environnement local' }
							action={ {
								label: 'Allez sur Preprod',
								path: 'https://www.publisur.dev'
							} }
							handleActive={ this.handleActiveSandBox.bind(this) }
						/>
					}
					<div style={ LayoutStyles.devTopBar }>
						<TopBar />
					</div>
					<div style={ {
						...LayoutStyles.mainContainer,
						height: `calc(100% - 50px - ${ this.state.isSandBoxActive ? 44 : 0 }px)`
					} }>
						<SidebarMenu />

						{/* CONTENT OUTLET */ }
						<Content>
							{ this.props.children }
						</Content>
					</div>
				</div>
				{/* Notification Create */ }
				<NotificationView
					isOpen={ this.state.isOpenNotificationView }
					handleOpen={ this.handleOpenNotificationView.bind(this) }
					notificationContext={ this.props.notificationContext! }
					navigation={ this.props.navigation }
				/>
			</>
		);
	}

	//<editor-fold desc="Create (state, didMount, ...) methods" defaultstate="collapsed">

	private initState(): LayoutState
	{
		return {
			isAvatarCardOpen: false,
			isModalOpen: false,
			isThemeDark: true,
			isOpenNotificationView: false,
			isNotification: false,
			isSandBoxActive: AppService.getEnv('dev')
		};
	}

	//</editor-fold>

	//<editor-fold desc="Private Method" defaultstate="collapsed">

	private handleOpenNotificationView(): void
	{
		this.setState(prevState => ({
			isOpenNotificationView: !prevState.isOpenNotificationView,
		}));
	}

	private handleActiveSandBox(isOpen: Boolean): void
	{
		this.setState({ isSandBoxActive: false });
	}

	//</editor-fold>
}

export default withGlobalContext(LayoutDeveloper);