import React, { createContext, ReactElement } from 'react';
import { OffcanvaContextType, OffcanvaProps, OffcanvaState } from '@/Provider/Offcanva/Offcanva.interface';
import { OffcanvaStyles } from '@/Provider/Offcanva/Offcanva.styles';
import { OffcanvaService } from '@/Provider/Offcanva/Offcanva.service';
import { ClipLoader } from 'react-spinners';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import OffCanvasHeaderActionsComponent from '@/Modules/App/Components/Atom/OffCanvas/OffCanvasHeaderActionsComponent';
import Title from '@/Modules/App/Components/Atom/Title/Title';
import ToggleOpenClosed from '@/Modules/App/Components/Library/ToggleOpenClosed/ToggleOpenClosed';
import { UserService } from '@/Modules/App/Services/User/User.service';

export const OffcanvaContext = createContext<OffcanvaContextType | undefined>(undefined);

class OffcanvaProvider extends React.Component<OffcanvaProps, OffcanvaState>
{
  private offcanvaService = new OffcanvaService();
  private setToggleIsOpen: ((isOpen: boolean) => void) | null = null;

  constructor(props: OffcanvaProps)
  {
    super(props);

    // Config
    this.offcanvaService.setProps(this.props);
    this.offcanvaService.subscribeState(this);

    // State
    this.state = this.offcanvaService.getState();
  }

  render(): ReactElement
  {
    return (
      <OffcanvaContext.Provider value={ {
        isLoadingFull: this.offcanvaService.isLoadingUpdated.bind(this),
        isLoadingOverlay: this.offcanvaService.isLoadingOverlay.bind(this),
        content: (title: string | ReactElement | null, content: ReactElement, isStack: boolean = false) =>
        {
          if (!isStack) {
            this.offcanvaService.clearOffcanvas();
          }
          this.offcanvaService.offcanvaContent(title, content);
          this.setToggleIsOpen && this.setToggleIsOpen(true);
        },
        isOpen: (isOpen) =>
        {
          this.offcanvaService.isOpen(isOpen);
          this.setToggleIsOpen && this.setToggleIsOpen(isOpen);
        }
      }}>
        { this.state.offcanvaStack.map((offcanva, index) => (
          <ToggleOpenClosed
            key={`${index}-${offcanva.title}`}
            isClickOutside={ true }
            forceIsOpen={ offcanva.isOpen }
          >
            { ({ close, isOpen, setIsOpen, menuRef }) =>
            {
              // Update `setIsOpen`
              this.setToggleIsOpen = setIsOpen;

              return (
                <div
                  ref={ menuRef }
                  style={ {
                    ...(UserService.isDeveloper()) ? OffcanvaStyles.devContainer : OffcanvaStyles.container,
                    transform: isOpen ? 'translateX(0)' : 'translateX(100%)',
                    transition: 'transform 270ms ease',
                  }}
                >
                  { this.state.isLoadingOverlay && (
                    <div style={ OffcanvaStyles.overlay }>
                      <ClipLoader
                        color={ colors.gray400 }
                        loading={ true }
                        size={ 50 }
                        aria-label="Loading Overlay"
                        data-testid="loader-overlay"
                      />
                    </div>
                  ) }

                  { offcanva.isLoading ? (
                    <div style={ UserService.isDeveloper() ? OffcanvaStyles.devLoading : OffcanvaStyles.loading }>
                      <ClipLoader
                        color={ colors.gray400 }
                        loading={ true }
                        size={ 24 }
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </div>
                  ) : (
                    <>
                      <OffCanvasHeaderActionsComponent
                        isNavigateArrow={ !!this.props.isNavigateArrow }
                        onHide={ () =>
                        {
                          close();
                          setIsOpen(false);
                        }}
                        onPrevious={ this.props.onPrevious }
                        onNext={ this.props.onNext }
                        isSellsyId={ false }
                      />

                      <div style={ OffcanvaStyles.content }>
                        { offcanva.title && (
                          <Title type="h6" style={ { marginTop: 0 } }>{ offcanva.title }</Title>
                        )}
                        { offcanva.content }
                      </div>
                    </>
                  )}
                </div>
              );
            }}
          </ToggleOpenClosed>
        ))}

        {/* Display children */ }
        { this.props.children }
      </OffcanvaContext.Provider>
    );
  }
}

export default OffcanvaProvider;
