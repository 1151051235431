import React, { Component, ReactElement } from 'react';
import { LegalNoticeDeveloperService } from './LegalNoticeDeveloper.service';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import LoaderFullPage from '@/Modules/App/Components/Loader/LoaderFullPage/LoaderFullPage';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { dateFormat } from '@/Utils/DateUtils';
import { UserService } from '@/Modules/App/Services/User/User.service';
import { SectionCollapsedStyles } from '@/Modules/App/Components/Sections/SectionCollapsed/SectionCollapsed.styles';
import Editable from '@/Modules/App/Components/Library/Editable/Editable';
import { UserDetailsStyles } from '@/Modules/User/Components/Blocks/UserDetails/UserDetails.styles';
import LineDetailsRender from '@/Modules/App/Components/Library/LineDetailsRender/LineDetailsRender';
import Tag from '@/Modules/App/Components/Library/Tag/Tag';
import { handleChangeInput } from '@/Utils/InputFormUtils';
import {
	LegalNoticeDeveloperProps,
	LegalNoticeDeveloperState
} from '@/Modules/LegalNotice/Developer/Show/LegalNoticeDeveloper.interface';

class LegalNoticeDeveloper extends Component<LegalNoticeDeveloperProps, LegalNoticeDeveloperState>
{
	private legalNoticeService: LegalNoticeDeveloperService = new LegalNoticeDeveloperService();

	constructor(props: LegalNoticeDeveloperProps)
	{
		super(props);

		// Config service
		this.legalNoticeService.setProps(this.props);
		this.legalNoticeService.subscribeState(this);

		// State
		this.state = this.legalNoticeService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.legalNoticeService.init();
	}

	componentWillUnmount(): void
	{
		this.legalNoticeService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		// Init const
		const { isLoading, formData } = this.state;
		const { legalNotice } = this.props;
		const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			handleChangeInput(event, this.setState.bind(this));
		};

		if (isLoading) {
			return <LoaderFullPage />;
		}

		return (
			<>
				<div style={ { lineHeight: 0.5, marginBottom: 20 } }>
					<h4 style={{ color: colors.white }}>
						# TAG : { legalNotice.tag }
					</h4>
					<div style={ { fontSize: 11, color: colors.gray400 } }>ID : { legalNotice.id }</div>
				</div>

				{/* Reproduce the section collap style */}
				<div style={ SectionCollapsedStyles.container }>
					<div style={ SectionCollapsedStyles.heading(UserService.isDeveloper(), colors.blueRibbon50) }>
						<div style={ SectionCollapsedStyles.title }>
							Information Annonce
						</div>
					</div>
				</div>

				<Editable onEdit={ () => this.legalNoticeService.handleEdit(this.state) }>
					{ ({ isEditable }) => (
						<div style={ UserDetailsStyles.container }>
							<div style={ UserDetailsStyles.column }>
								<LineDetailsRender
									info={ 'Annonce créer le' }
									formData={ dateFormat(formData.createdAt) }
									isEditable={ isEditable }
								/>
								<LineDetailsRender
									info={ 'Statut' }
									render={ <Tag value={ formData.status.toString() } enumName={ 'PublishStateEnum' }/> }
									inputEdit={ { type: 'text', name: 'status', onChange }}
									formData={ formData.status.toString() }
									isEditable={ isEditable }
								/>

								<LineDetailsRender
									info={ 'Statut de paiement' }
									render={ <Tag value={ formData.paymentStatus.toString() } enumName={ 'PaymentStateEnum' }/> }
									inputEdit={ { type: 'text', name: 'paymentStatus', onChange }}
									formData={ formData.paymentStatus.toString() }
									isEditable={ isEditable }
								/>

								<LineDetailsRender
									info={'Statut du devis'}
									render={
										formData.quoteStatus
											? <Tag value={formData.quoteStatus.toString()} enumName={'QuoteStatusEnum'} />
											: <>-</>
									}
									inputEdit={{ type: 'text', name: 'quoteStatus', onChange }}
									formData={formData.quoteStatus ? formData.quoteStatus.toString() : '-'}
									isEditable={isEditable}
								/>
							</div>
						</div>
					) }
				</Editable>
			</>
		);
	}
}

export default withGlobalContext(LegalNoticeDeveloper);
