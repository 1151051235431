import React, { Component, ReactElement } from 'react';
import { CreateUserFormProps, CreateUserFormState } from './CreateUserForm.interface';
import { CreateUserFormService } from './CreateUserForm.service';
import { GenderUserEnum } from '@/Modules/User/Enum/GenderUserEnum';
import Input from '@/Modules/App/Components/Library/Input/Input';
import { handleChangeInput } from '@/Utils/InputFormUtils';
import { UserRoleEnum } from '@/Modules/User/Enum/UserRoleEnum';
import ErrorBanner from '@/Modules/App/Components/Error/ErrorBanner/ErrorBanner';
import Select from '@/Modules/App/Components/Library/Select/Select';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { CreateUserFormStyles } from '@/Modules/User/Components/Form/Create/CreateUserForm.styles';
import { _GridStyles } from '@/Modules/App/Style/Components/_Grid.styles';
import { UserService } from '@/Modules/App/Services/User/User.service';

class CreateUserForm extends Component<CreateUserFormProps, CreateUserFormState>
{
	private createUserFormService = new CreateUserFormService();

	constructor(props: CreateUserFormProps)
	{
		super(props);

		// Config service
		this.createUserFormService.setProps(this.props);
		this.createUserFormService.subscribeState(this);

		// State
		this.state = this.createUserFormService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	componentDidMount()
	{
		this.createUserFormService.init();
	}

	componentWillUnmount(): void
	{
		this.createUserFormService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const { user } = this.state.formData;
		return (
			<>
				{ this.state.errorMessage &&
          <ErrorBanner
            key={ this.state.errorMessage }
            errorMessage={ this.state.errorMessage }
          />
				}
				<div style={ CreateUserFormStyles.container }>
					<div style={ CreateUserFormStyles.form }>
						<Select
							label={ 'Civilité' }
							text={ 'Choisissez une civilité...' }
							listOptions={ GenderUserEnum.options }
							displayKey={ 'label' }
							onSelect={ (selectedItem) => this.createUserFormService.handleSelectCivility(selectedItem.value, this.state) }
							isRequired={ true }
						/>
						<div style={ _GridStyles._2col() }>
							<Input
								type={ 'text' }
								name={ 'user.lastname' }
								label={ 'Nom' }
								value={ user.lastname || '' }
								onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
								width={ '100%' }
								allowedCharacters={ 'letters' }
								required={ true }
							/>
							<Input
								type={ 'text' }
								name={ 'user.firstname' }
								label={ 'Prénom' }
								value={ user.firstname || '' }
								onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
								width={ '100%' }
								allowedCharacters={ 'letters' }
								required={ true }
							/>
						</div>
						<div style={ _GridStyles._2col() }>
							<Input
								type={ 'text' }
								name={ 'user.phone' }
								label={ 'Téléphone' }
								value={ user.phone || '' }
								onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
								width={ '100%' }
								allowedCharacters={ 'digits' }
								required={ false }
							/>

							{(!UserService.isAdmin() && !UserService.isSuperAdmin()) &&
								<Input
									type={ UserService.isDeveloper() ? 'text' : 'password' }
                  label={ 'Mot de passe' }
									name={'user.password'}
                  value={ user?.password || '' }
                  onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
                  required={ true }
								/>
							}

							{ (UserService.isAdmin() || UserService.isSuperAdmin() || UserService.isDeveloper()) &&
								<Select
									label={ 'Rôle' }
									text={ UserService.isAdmin() ? 'Utilisateur (par défaut)' : 'Choisissez un rôle...' }
									listOptions={ (UserService.isDeveloper()) ? UserRoleEnum.fullGroup : UserRoleEnum.filteredOptions }
									displayKey={ 'label' }
									onSelect={ (selectedItem: UserRoleEnum) =>
										this.createUserFormService.handleSelectUserRole(selectedItem.value, this.state) }
									isRequired={ true }
									isDisabled={ UserService.isAdmin() }
									value={ UserService.isAdmin() ? 'ROLE_USER' : this.state.formData.user?.role }
								/>
							}
						</div>
						<Input
							type={ 'text' }
							name={ 'user.email' }
							label={ 'Email' }
							placeholder={ 'john.doe@societe.com' }
							value={ user.email || '' }
							onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
							width={ '100%' }
							required={ true }
						/>

						{ UserService.isDeveloper() &&
							<Input
								type={ 'text' }
								name={ 'user.company' }
								label={ 'Company' }
								placeholder={ 'company ID' }
								onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
								width={ '100%' }
								required={ true }
							/>
						}
					</div>
					<div style={ CreateUserFormStyles.button }>
						<a id={ 'required-field-user-form' } href={ '#required-field-user-form' }>
							<Button
								variant={ 'primary' }
								label={ 'Nouvel utilisateur' }
								iconName={ 'LuPlus' }
								onClick={ (event: any) => this.createUserFormService.handleFormSubmit(event, this.state) }
							/>
						</a>
					</div>
				</div>
			</>
		);
	}
}

export default CreateUserForm;