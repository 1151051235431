import { FiltersInterface } from '@/Modules/App/Components/Library/Table/Table.interface';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import PaymentStateEnum from '@/Enum/PaymentStateEnum';
import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
  LegalNoticesProps,
  LegalNoticesState, LegalNoticesType
} from '@/Modules/LegalNotice/Common/LegalNotices.interface';
import { CreateUserFormSchema } from '@/Modules/User/Components/Form/Create/CreateUserForm.interface';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';
import { paramsToString } from '@/Utils/HandleParamsUtils';
import { ApiAdminLegalNoticeService } from '@/Service/Admin/ApiAdminLegalNoticeService';
import { FilterParamsInterface } from '@/Modules/App/Interface/FilterParamsInterface';
import { ApiLegalNoticeService } from '@/Service/Api/ApiLegalNoticeService';
import { UserService } from '@/Modules/App/Services/User/User.service';
import LocalStorageService from '@/Service/Common/LocalStorageService';

export class BaseLegalNoticesService extends BaseComponentService<LegalNoticesProps, LegalNoticesState>
{
  apiLegalNoticeAdminService = new ApiAdminLegalNoticeService();
  apiLegalNoticeService = new ApiLegalNoticeService();

  // Common state for every child
  static baseInitState: LegalNoticesState = {
    isLoading: false,
    errorMessage: null,
    legalNoticeList: {} as PaginateInterface,
    filters: {},
    refreshKey: 0,
    filterParams: {
      page: 1,
      itemsPerPage: 15,
      search: null,
      filters: null,
      orderBy: null,
      sort: null,
    },
  };

  /**
   * Initialize the service by setting the context list and name
   * @return Promise<void>
   */
  async init(type: LegalNoticesType): Promise<void>
  {
    this.setState({ isLoading: true });

    await this.list({ type: type, filterParams: this.state.filterParams });
  }

  // @ts-ignore
  async list({ type, filterParams }: { type: LegalNoticesType, filterParams: FilterParamsInterface }): Promise<PaginateInterface>
  {

    try {
      const params = typeof filterParams === 'string' ? filterParams : paramsToString(filterParams);

      if( UserService.isSuperAdmin() || UserService.isAdmin() ) {
        switch (type) {
          case 'validate':
            return await this.apiLegalNoticeAdminService.specificList('validate', params);
          case 'quote':
            return await this.apiLegalNoticeAdminService.specificList('quote', params);
          case 'draft':
            return await this.apiLegalNoticeAdminService.specificList('draft', params);
          case 'regular':
            return await this.apiLegalNoticeAdminService.list(params);
        }
      } else {
        switch (type) {
          case 'regular':
            return await this.apiLegalNoticeService.list(LocalStorageService.getAuthClient(), params);
        }
      }

    } catch (error) {
      console.error('Failed to fetch legal notice list', error);

      return {
        items: [],
        totalCount: 0,
        totalPages: this.state.filterParams.page || 1,
        itemsPerPage: this.state.filterParams.itemsPerPage || 10,
      };
    }
  }

  async clientList(clientId: number, filterParams: string): Promise<PaginateInterface>
  {
    const params = typeof filterParams === 'string' ? filterParams : paramsToString(filterParams);
    return await this.apiLegalNoticeAdminService.clientList(clientId, params);
  }

  buildFilters(): FiltersInterface[]
  {
    return [
      {
        title: 'Statut',
        tag: 'status',
        type: 'selector',
        options: PublishStateEnum.optionsFilter
      },
      {
        title: 'Statut de paiement',
        tag: 'paymentStatus',
        type: 'selector',
        options: PaymentStateEnum.optionsFilter
      },
      {
        title: 'Département (code)',
        tag: 'department',
        type: 'search',
      },
      {
        title: 'Journal',
        tag: 'newspaper',
        type: 'search',
      },
      {
        title: 'date',
        tag: 'publishDate',
        type: 'date',
      }
    ];
  }
}