import { UserRoleEnum } from '@/Modules/User/Enum/UserRoleEnum';
import LocalStorageService from '@/Service/Common/LocalStorageService';
import UserCivilityEnum from '@/Enum/UserCivilityEnum';
import { UserInterface } from '@/Modules/User/Interface/UserInterface';
import { ApiAdminUserService } from '@/Service/Admin/ApiAdminUserService';

export class UserService
{
	private apiAdminUserService: ApiAdminUserService = new ApiAdminUserService();

	/**
	 * Check if the current user has the Super Admin role
	 * @return boolean - true if the user is a Super Admin, false otherwise
	 */
	static isSuperAdmin(): boolean
	{
		return UserRoleEnum.findByValue(LocalStorageService.getUser().userRole) === UserRoleEnum.ROLE_SUPER_ADMIN;
	}

	/**
	 * Check if the current user has the Admin role
	 * @return boolean - true if the user is an Admin, false otherwise
	 */
	static isAdmin(): boolean
	{
		return UserRoleEnum.findByValue(LocalStorageService.getUser().userRole) === UserRoleEnum.ROLE_ADMIN;
	}

	static isDeveloper(): boolean
	{
		return UserRoleEnum.findByValue(LocalStorageService.getUser().userRole) === UserRoleEnum.ROLE_DEVELOPER;
	}

	/**
	 * Convert Civility Enum value to French Civility
	 * @param civility
	 * @return string - The French civility ('Mme' or an empty string)
	 */
	static convertCivilityToFrench(civility: string): string
	{
		if (civility !== UserCivilityEnum.M.value) {
			if (civility === UserCivilityEnum.W.value) {
				return 'Mme';
			} else {
				return '';
			}
		}

		return civility;
	}

	/**
	 * Get the current user's role from LocalStorage
	 * @return UserRoleEnum - The role of the current user
	 */
	getUserRole(): UserRoleEnum
	{
		return UserRoleEnum.findByValue(LocalStorageService.getUser().userRole);
	}

	/**
	 * Get Auth User
	 * @return UserRoleEnum
	 */
	async getAuthUser(): Promise<UserInterface|undefined>
	{
		try {
			const user = await this.apiAdminUserService.show(LocalStorageService.getUser().userId);

			if(user.error) {
				throw new Error(user.errorMessage);
			}

			return user;

		} catch (e) {
			console.error('Error Occurred on GetAuthUser - USERSERVICE');
		}
	}
}
