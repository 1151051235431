import React, { ReactElement } from 'react';
import { BsCopy } from 'react-icons/bs';
import HeroSection from '@/Modules/App/Components/HeroSection/HeroSection';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { CollaboratorInterface } from '@/Modules/Collaborator/Interface/CollaboratorInterface';
import { LegalNoticeService } from '@/Service/LegalNoticeService';
import { NewspaperInterface } from '@/Modules/LegalNotice/Interface/NewspaperInterface';
import { NewspaperTypeEnum } from '@/Enum/NewspaperTypeEnum';
import BlockClientComponent from '@/Modules/LegalNotice/Components/Form/Admin/BlockClientComponent';
import BlockConfigComponent from '@/Modules/LegalNotice/Components/Form/Admin/BlockConfigComponent';
import { DepartmentInterface } from '@/Modules/LegalNotice/Interface/DepartmentInterface';
import BlockCategoryComponent from '@/Modules/LegalNotice/Components/Form/Admin/BlockCategoryComponent';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import BlockConsumerComponent from '@/Modules/LegalNotice/Components/Form/Admin/BlockConsumerComponent';
import LegalNoticeTypeEnum from '@/Enum/LegalNoticeTypeEnum';
import BlockBodaccComponent from '@/Modules/LegalNotice/Components/Form/Admin/BlockBodaccComponent';
import { Location, NavigateFunction } from 'react-router-dom';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import { ModalContextType } from '@/Provider/ModalProvider';
import { ConsumerDataInterface } from '@/Modules/LegalNotice/Interface/ConsumerDataInterface';
import { BilledToTypeEnum } from '@/Modules/Client/Enum/BilledToTypeEnum';
import { AddressInterface } from '@/Modules/Client/Interface/AddressInterface';
import BlockSendToComponent from '@/Modules/LegalNotice/Components/Form/Admin/BlockSendToComponent';
import { SendToSelections } from '@/Modules/LegalNotice/Components/Form/LegalNoticeSendToFormComponent';
import BlockLegalNoticeContent from '@/Modules/LegalNotice/Components/Form/Admin/BlockLegalNoticeContent';
import { ContentState, convertFromRaw } from 'draft-js';
import { LegalNoticeModal } from '@/Modules/LegalNotice/Components/LegalNoticeModal';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import { ApiAdminLegalNoticeService } from '@/Service/Admin/ApiAdminLegalNoticeService';
import LoaderFullPageComponent from '@/Modules/App/Components/LoaderFullPageComponent';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import ButtonOld from '@/Modules/App/Components/Atom/Button/ButtonOld';
import TagEnumOld from '@/Modules/App/Components/Atom/Tags/TagEnumOld';
import PaymentStateEnum from '@/Enum/PaymentStateEnum';
import { stripHtmlTags } from '@/Utils/StripHtmlTags';
import { LegalNoticePriceService } from '@/Service/LegalNoticePriceService';
import { LuRepeat } from 'react-icons/lu';
import QuoteStatusEnum from '@/Enum/QuoteStatusEnum';
import Swal from 'sweetalert2';
import { CreateLegalNoticeStyle } from '@/Modules/LegalNotice/Style/CreateLegalNoticeStyle';
import { AuthCompanyContextType } from '@/Provider/Interface/AuthCompany/AuthCompanyContextType';
import { LegalNoticeInterface } from '@/Modules/LegalNotice/Interface/LegalNoticeInterface';
import BlockSearchSellsyClientComponent
	from '@/Modules/LegalNotice/Components/Form/Admin/Consumer/BlockSearchSellsyClientComponent';
import { ApiAdminLegalNoticeFileService } from '@/Service/Admin/ApiAdminLegalNoticeFileService';
import { Alert } from 'react-bootstrap';
import BlockNewConsumerAddressComponent
	from '@/Modules/LegalNotice/Components/Form/Admin/Consumer/BlockNewConsumerAddressComponent';
import BlockNewConsumerNameComponent
	from '@/Modules/LegalNotice/Components/Form/Admin/Consumer/BlockNewConsumerNameComponent';
import { ApiPublicService } from '@/Service/Api/ApiPublicService';
import { NotificationContextType } from '@/Provider/NotificationProvider';
import LegalNoticeFileTypeEnum from '@/Enum/LegalNoticeFileTypeEnum';
import { AuthContextType } from '@/Provider/Interface/Auth/AuthContextType';
import BlockConfigBillingComponent from '@/Modules/LegalNotice/Components/Form/Admin/BlockConfigBillingComponent';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { ClientDiscountPreferenceEnum } from '@/Modules/Client/Enum/ClientDiscountPreferenceEnum';
import LoaderSwitchContext from '@/Modules/App/Components/Loader/LoaderSwitchContext/LoaderSwitchContext';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import LocalStorageService from '@/Service/Common/LocalStorageService';

interface ViewProps
{
	navigate: NavigateFunction,
	location: Location,
	flashMessageContext: FlashMessageContextType,
	notificationContext: NotificationContextType,
	authCompanyContext: AuthCompanyContextType,
	authContext: AuthContextType,
	modalContext: ModalContextType,
	navigation: NavigateFunction
}

export interface ViewState
{
	errorMessage: string | null,
	departmentList: DepartmentInterface[],
	newspaperList: NewspaperInterface[],
	formBuilderCategoryList: FormBuilderCategoryInterface[],
	isConsumerFormComplete: boolean
	formData: ViewStateFormData,
	isEdit: boolean,
	isDataLoading: boolean,
	isCreateLoading: boolean,
	progressProcess: number
}

export interface ViewStateFormData
{
	selectedClient: ClientInterface | null,
	selectedCollaborator: CollaboratorInterface | null,
	selectedDepartment: DepartmentInterface | null,
	selectedNewspaper: NewspaperInterface | null,
	selectedPrimaryCategory: FormBuilderCategoryInterface | null,
	selectedSecondaryCategory: FormBuilderCategoryInterface | null,
	newspapers: NewspaperInterface[],
	consumer: ConsumerDataInterface | null,
	billingAddress: AddressInterface | null,
	sellsyConsumerAddress: AddressInterface | null,
	changeOf: {
		name: string | null,
		address: AddressInterface | null
	},
	headerCharacterCount: number,
	sendTo: {},
	legalNotice: ViewLegalNoticeState
}

export interface ViewLegalNoticeState
{
	id: number | null,
	tag: string | null,
	title: string,
	content: string,
	signature: string,
	logo: string,
	publishDate: Date | null,
	isForcePublishDate: boolean,
	discount: number,
	configBillingPreference: {
		billing: string | null,
		creditNote: string | null
	},
	numberOfCopies: number,
	reference: string | null,
	status: PublishStateEnum | null,
	paymentStatus: PaymentStateEnum | null,
	quoteStatus: QuoteStatusEnum | null,
	option: {
		publishType: NewspaperTypeEnum | string,
		billingType: BilledToTypeEnum,
		isHeader: boolean,
		isLogo: boolean,
		isBodacc: boolean
	},
	extSupplierUid: any | null
}

export interface ExtractedLegalNoticeDataInterface
{
	capital: string | null,
	rcs: string | null,
	address: {
		street: string | null,
		number: string | null,
		city: string | null,
	};
}

export default class CreateLegalNoticeView extends React.Component<ViewProps, ViewState>
{
	private timeoutId: NodeJS.Timeout | null = null;

	// Properties
	legalNoticeService: LegalNoticeService;
	legalNoticePriceService: LegalNoticePriceService;
	apiLegalNoticeService: ApiAdminLegalNoticeService;
	apiLegalNoticeFileService: ApiAdminLegalNoticeFileService;
	apiPublicService: ApiPublicService;
	navigation: NavigateFunction;

	constructor(props: any)
	{
		super(props);

		// Init state
		this.state = this.initState();

		// Navigation
		this.navigation = props.navigation;

		// Service
		this.legalNoticeService = new LegalNoticeService();
		this.legalNoticePriceService = new LegalNoticePriceService(true);
		this.apiLegalNoticeService = new ApiAdminLegalNoticeService();
		this.apiLegalNoticeFileService = new ApiAdminLegalNoticeFileService();
		this.apiPublicService = new ApiPublicService();

		// Document title
		document.title = 'Publisur - ADMIN - Formulaire annonce légale';
	}

	render(): ReactElement
	{
		if (this.state.isDataLoading) {
			return <LoaderFullPageComponent/>;
		}

		if (this.state.isCreateLoading) {
			return <LoaderSwitchContext textLoading={ 'Creation de votre annonce légale en cours...' }/>;
		}

		const formData = this.state.formData;
		return (
			<>
				<HeroSection
					title={ this.state.isEdit ? '' : 'Créer une annonce légale' }
					extendTitle={ this.state.isEdit ? this.renderTitle(this.state.formData.legalNotice) : <></> }
					icon={ null }
					button={ {
						label: 'Retour',
						variant: 'secondary',
						iconName: 'LuArrowUpLeft',
						onClick: () => this.props.navigation('/admin/legal-notices')
					} }
				/>
				<div style={ CreateLegalNoticeStyle.sideContainerStyle() }>
					{/* LEFT SIDE */ }
					<div style={ { gridColumn: '1 / 2', paddingBottom: '50px' } }>
						{ this.leftSiteRender(formData) }
					</div>
					{/* RIGHT SIDE */ }
					<div style={ { gridColumn: '2 / 3' } }>
						<div style={ {
							backgroundColor: colors.gray100,
							borderRadius: 8,
							padding: 20,
							display: 'flex',
							flexDirection: 'column',
							gap: 20,
							position: 'sticky',
							top: 50,
						} }>
							<BlockLegalNoticeContent
								modalContext={ this.props.modalContext }
								flashMessageContext={ this.props.flashMessageContext }
								selectedClient={ formData.selectedClient }
								isConsumerFormFilled={ this.state.isConsumerFormComplete }
								isFormComplete={ this.isFormComplete() }
								consumer={ formData.consumer }
								legalNoticePriceInit={
									{
										selectedDepartment: formData.selectedDepartment,
										selectedNewspaper: formData.selectedNewspaper,
										selectedCategory: formData.selectedSecondaryCategory,
										validateLegalNotice: this.validateLegalNotice.bind(this),
										isHeaderCharacterCount: formData.headerCharacterCount,
									}
								}
								legalNotice={ formData.legalNotice }
								content={ formData.legalNotice.content }
								title={ formData.legalNotice.title }
								signature={ formData.legalNotice.signature }
								isHeader={ formData.legalNotice.option.isHeader }
								isLogo={ formData.legalNotice.option.isLogo }
								onSelectedLogo={ this.handleSelectedLogo.bind(this) }
								onCreate={ this.validateLegalNotice.bind(this) }
								onCheckIsHeader={ this.onCheckContentHeaderIsHeader.bind(this) }
								onCheckIsLogo={ this.onCheckContentHeaderIsLogo.bind(this) }
								onChangeLegalNoticeTitle={ this.onChangeLegalNoticeTitle.bind(this) }
								onChangeLegalNoticeContent={ this.onChangeLegalNoticeContent.bind(this) }
								onChangeLegalNoticeSignature={ this.onChangeLegalNoticeSignature.bind(this) }
								onHeaderCharacterCount={ this.isHeaderCharacterCount.bind(this) }
							/>
						</div>
					</div>
				</div>
			</>
		);
	}

	//<editor-fold desc="Render methods" defaultstate="collapsed">

	private leftSiteRender(formData: ViewStateFormData): ReactElement
	{
		return (
			<>
				<BlockClientComponent
					selectedClient={ formData.selectedClient }
					selectedCollaborator={ formData.selectedCollaborator }
					onSelectedClient={ this.onSelectedClient.bind(this) }
					onSelectedCollaborator={ this.onSelectedCollaborator.bind(this) }
					onReset={ this.resetFormData.bind(this) }
				/>

				{ this.state.errorMessage ? (
					<Alert variant={ 'danger' } style={ { display: 'flex', gap: 10 } }>
						{ this.state.errorMessage }
						{ this.renderAssociateSellsyButton() }
					</Alert>
				) : (
					<>
						<BlockConfigComponent
							isDisplayBlock={ Boolean(formData.selectedClient && formData.selectedCollaborator) }
							isDisabled={ this.state.isEdit && formData.legalNotice.status !== PublishStateEnum.DRAFT }
							authContext={ this.props.authContext }
							departmentList={ this.state.departmentList }
							newspaperList={ this.state.newspaperList }
							newspaperListRequest={ this.newspaperListRequest.bind(this) }
							selectedNewspaperType={ formData.legalNotice.option.publishType as NewspaperTypeEnum }
							onSelectedNewspaperType={ this.onSelectedNewspaperType.bind(this) }
							selectedDepartment={ formData.selectedDepartment }
							onSelectedDepartment={ this.onSelectedDepartment.bind(this) }
							selectedNewspaper={ formData.selectedNewspaper }
							onSelectedNewspaper={ this.onSelectedNewspaper.bind(this) }
							publishDate={ formData.legalNotice.publishDate }
							isForcePublishDate={ formData.legalNotice.isForcePublishDate }
							onChangePublishDate={ this.onChangePublishDate.bind(this) }
							onForcePublishDate={ this.onForcePublishDate.bind(this) }
							numberOfCopies={ formData.legalNotice.numberOfCopies }
							onChangeNumberOfCopies={ this.onChangeNumberOfCopies.bind(this) }
							reference={ formData.legalNotice.reference }
							onChangeReference={ this.onChangeReference.bind(this) }
							isInputRefNeeded={ true }
							isAdmin={ true }
						/>

						<BlockCategoryComponent
							isDisplayBlock={ Boolean(formData.selectedCollaborator && formData.selectedNewspaper) }
							FormBuilderCategoryList={ this.state.formBuilderCategoryList }
							selectedPrimaryCategory={ formData.selectedPrimaryCategory }
							onSelectedPrimaryCategory={ this.onSelectedPrimaryCategory.bind(this) }
							selectedSecondaryCategory={ formData.selectedSecondaryCategory }
							onSelectedSecondaryCategory={ this.onSelectedSecondaryCategory.bind(this) }
							onReset={ this.onResetBlockCategory.bind(this) }
						/>

						<BlockConsumerComponent
							key={ `${ this.state.formData.selectedPrimaryCategory?.id }` }
							consumer={ this.state.formData.consumer }
							isDisplayBlock={ Boolean(formData.selectedNewspaper && formData.selectedPrimaryCategory && formData.selectedSecondaryCategory) }
							category={ this.state.formData.selectedPrimaryCategory }
							onFormsComplete={ this.onConsumerFormComplete.bind(this) }
						/>

						<BlockBodaccComponent
							isDisplayBlock={ Boolean(
								formData.selectedNewspaper
								&& formData.selectedSecondaryCategory
								&& formData.selectedSecondaryCategory.categoryType === LegalNoticeTypeEnum.BUSINESS_ASSETS.value
							) }
							isBodacc={ formData.legalNotice.option.isBodacc }
							onSelectedIsBodacc={ this.onSelectedIsBodacc.bind(this) }
						/>

						{ formData.selectedClient &&
              <BlockConfigBillingComponent
                isDisplayBlock={ Boolean(formData.selectedNewspaper && this.state.isConsumerFormComplete) }
                discount={ (formData.legalNotice.discount) ? formData.legalNotice.discount : 0 }
                client={ formData.selectedClient }
                onChangeDiscount={ this.onChangeDiscount.bind(this) }
                selectedCategory={ formData.selectedSecondaryCategory?.categoryType }
                selectedAffiliationBilling={ (formData.legalNotice.configBillingPreference.billing)
									? formData.legalNotice.configBillingPreference.billing
									: 'CLIENT'
								}
                selectedAffiliationCreditNote={ (formData.legalNotice.configBillingPreference.creditNote)
									? formData.legalNotice.configBillingPreference.creditNote
									: 'CLIENT'
								}
                onSelectedAffiliationBilling={ this.onSelectedAffiliationBilling.bind(this) }
                onSelectedAffiliationCreditNote={ this.onSelectedAffiliationCreditNote.bind(this) }
              />
						}

						<BlockSearchSellsyClientComponent
							key={
								`${ this.state.formData.selectedPrimaryCategory?.id }
								-${ this.state.formData.changeOf?.address?.toString() }
								-${ this.state.formData.changeOf?.name?.toString() }
								-${ this.state.formData.legalNotice.configBillingPreference.billing?.toString() }`
							}
							isDisplayBlock={ Boolean(this.state.formData.legalNotice.configBillingPreference.billing === 'CONSUMER' && formData.selectedPrimaryCategory && formData.selectedSecondaryCategory && this.state.isConsumerFormComplete) }
							authCompanyContext={ this.props.authCompanyContext }
							selectedClient={ formData.selectedClient }
							consumer={ formData.consumer }
							formData={ formData.billingAddress }
							formDataFormSelly={ this.handleBillingAddressFromSellsy.bind(this) }
							sendExtSellsyIdForConsumer={ this.updateConsumerData.bind(this) }
							sendExtSellsyIdForAddress={ this.updateAddressData.bind(this) }
						/>

						<BlockNewConsumerAddressComponent
							isDisplayBlock={
								Boolean(this.state.formData.legalNotice.configBillingPreference.billing === 'CONSUMER' && formData.selectedSecondaryCategory?.label?.toLowerCase().includes('transfert de siège') && formData.consumer?.extSellsyId)
							}
							client={ formData.selectedClient as ClientInterface }
							consumer={ formData.consumer as ConsumerDataInterface }
							consumerSellsyAddress={ formData.sellsyConsumerAddress as AddressInterface }
							onNewAddress={ this.onNewAddress.bind(this) }
						/>

						<BlockNewConsumerNameComponent
							isDisplayBlock={
								Boolean(this.state.formData.legalNotice.configBillingPreference.billing === 'CONSUMER' && formData.selectedSecondaryCategory?.label?.toLowerCase().includes('changement de nom') && formData.consumer?.extSellsyId)
							}
							client={ formData.selectedClient as ClientInterface }
							onNewName={ this.onNewName.bind(this) }
						/>

						<BlockSendToComponent
							isDisplayBlock={ Boolean(formData.selectedNewspaper && this.state.isConsumerFormComplete) }
							selectedClient={ formData.selectedClient }
							sendTo={ formData.sendTo }
							onSelectionMail={ this.onSendToSelected.bind(this) }
							fieldsToShow={ ['invoice', 'certificate', 'receipt', 'credit'] }
						/>
					</>
				) }
			</>
		);
	}

	private renderTitle(legalNotice: any): ReactElement
	{
		return (
			<>
				<div style={ { display: 'flex', alignItems: 'center', gap: '16px' } }>
					<div style={ { fontSize: '26px', fontWeight: 600, color: CssVariableEnum['--color-grey-900'], } }>
						Annonce légale
					</div>
					<ButtonOld
						type={ 'default' }
						iconRight={ <BsCopy style={ { fontSize: '10px' } }/> }
						onClick={ () => navigator.clipboard.writeText(`${ legalNotice?.tag }`) }
						style={ { fontSize: '14px' } }
					>
						{ legalNotice?.tag }
					</ButtonOld>
					{ legalNotice &&
            <>
              <TagEnumOld
                style={ { maxHeight: '32px', minWidth: '71px', fontSize: 14 } }
                value={ (legalNotice) ? legalNotice?.status?.value : '' }
                enumName={ 'PublishStateEnum' }
              >
								{ legalNotice?.status?.label }
              </TagEnumOld>
							{ legalNotice?.quoteStatus &&
                <TagEnumOld
                  style={ { maxHeight: '32px', minWidth: '71px', fontSize: 14 } }
                  value={ (legalNotice) ? legalNotice?.quoteStatus?.value : '' }
                  enumName={ 'QuoteStatusEnum' }
                >
                  <b>Statut du devis: </b> { legalNotice?.quoteStatus?.label }
                </TagEnumOld>
							}

              <ButtonOld
                type={ 'default-dark' }
                iconRight={ <LuRepeat/> }
                onClick={ () => this.prepareComponentForEdit(true) }
                style={ { fontSize: '14px' } }
              >
                Revenir au données par défaut
              </ButtonOld>
            </>
					}
				</div>
			</>
		);
	}

	private renderAssociateSellsyButton(): ReactElement | null
	{
		if (this.state.errorMessage === 'Merci de lié ce collaborateur a un contact sellsy') {
			return (
				<Button
					label={ 'Voir la fiche client' }
					variant={ 'secondary' }
					onClick={ () => this.props.navigation(`/admin/clients/${ this.state.formData.selectedClient?.id }`) }
				/>
			);
		}
		return null;
	}

	//</editor-fold>

	//<editor-fold desc="Block Client methods" defaultstate="collapsed">

	private async onSelectedClient(client: ClientInterface): Promise<void>
	{
		const isDiscountPreferenceIsCreditReduced = client.options?.discountPreference && client?.options?.discountPreference.toString()!
			=== ClientDiscountPreferenceEnum.CREDIT_DEDUCTED.value
		;

		// Check required client options
		if (!client.options?.billedTo || !client.options?.discountPreference) {
			this.setState({
				errorMessage: 'Le client sélectionné ne dispose pas de toutes les options nécessaires pour créer une annonce légale.',
			});
			return;
		}

		// Reset error message if options are valid
		this.setState({
			errorMessage: null
		});

		// Get newspaperByDepartment
		if (client.options && client.options.department) {
			this.newspaperListRequest(
				client.options.department,
				NewspaperTypeEnum.findByValue(client.options.newspaperType)
			);
		}

		this.setFormData({
			selectedClient: client,
			selectedCollaborator: null,
			billingAddress: client.address,
			selectedDepartment: client.options?.department || this.state.formData.selectedDepartment,
			selectedNewspaper: client.options?.newspaper || this.state.formData.selectedNewspaper,
		}, () =>
		{
			this.handleSingleCollaborator();
		});

		// Set publication type if available
		if (client.options?.newspaperType) {
			this.setFormDataLegalNoticeOption({ publishType: client.options?.newspaperType });
		}

		// Merge and set sendTo
		const mergedSendTo = this.mergeSendTo(client.options?.sendTo, this.state.formData.sendTo);
		this.setFormData({ sendTo: mergedSendTo });

		// Set all data for legalNotice
		this.setFormDataLegalNotice({
			numberOfCopies: client.options?.numberOfCopies,
			discount: (this.state.isEdit) ? this.state.formData.legalNotice.discount : client.options?.discountGranted,
			configBillingPreference: {
				billing: isDiscountPreferenceIsCreditReduced
					? 'CLIENT'
					: (client.options?.billedTo && client.options?.billedTo === 'ORDER_GIVER')
						? 'CLIENT'
						: 'CONSUMER',
				creditNote: 'CLIENT'
			}
		});

		// Set option for legalNotice
		this.setFormDataLegalNoticeOption({
			publishType: client.options?.newspaperType || this.state.formData.legalNotice.option.publishType
		});
	}

	private handleSingleCollaborator(): void
	{
		// Selected Collaborator if list collaborator is length 1
		if (this.state.formData.selectedClient
			&& this.state.formData.selectedClient.collaborators
			&& this.state.formData.selectedClient.collaborators.length === 1) {
			this.onSelectedCollaborator(this.state.formData?.selectedClient?.collaborators[0]);
		}
	}

	private async onSelectedCollaborator(collaborator: CollaboratorInterface): Promise<void>
	{
		this.setFormData({ selectedCollaborator: collaborator },
			() =>
			{
				if (!collaborator.extSellsyId) {
					this.setState({ errorMessage: 'Merci de lié ce collaborateur a un contact sellsy' });
				}
				;
			}
		);
	}

	private resetFormData(): void
	{
		this.setFormData({
			selectedPrimaryCategory: null,
			selectedSecondaryCategory: null,
			selectedCollaborator: null,
			consumer: null,
			billingAddress: null,
			sendTo: []
		});
		this.setState({ isConsumerFormComplete: false });
		this.onResetBlockCategory();
	}

	//</editor-fold>

	//<editor-fold desc="Block Config methods" defaultstate="collapsed">

	private async onSelectedNewspaperType(newspaperType: NewspaperTypeEnum): Promise<void>
	{
		this.setFormDataLegalNoticeOption({ publishType: newspaperType });
		if (newspaperType === NewspaperTypeEnum.WEB) {
			this.setFormDataLegalNotice({ numberOfCopies: 0 });
			const newspaper = await this.apiPublicService.getNewspaper(49);
			this.setFormData({ selectedNewspaper: newspaper });
		} else if (newspaperType === NewspaperTypeEnum.PAPER) {
			const newspaper = await this.apiPublicService.getNewspaper(100);
			this.setFormData({ selectedNewspaper: newspaper });
			this.setFormDataLegalNotice({ numberOfCopies: this.state.formData.selectedClient?.options.numberOfCopies });
		} else {
			this.setFormData({ selectedNewspaper: null });
			this.setFormDataLegalNotice({ numberOfCopies: 0 });
		}

		if (this.state.formData.selectedDepartment) {
			this.newspaperListRequest(this.state.formData.selectedDepartment, newspaperType);
		}
	}

	private async onSelectedDepartment(department: DepartmentInterface): Promise<void>
	{
		const newspaperByDepartment: NewspaperInterface[] = await this.legalNoticeService.newspaperList(
			this.props.authContext?.user?.company?.id!,
			department,
			this.state.formData.legalNotice.option.publishType
		);

		this.setFormData({ selectedDepartment: department, selectedNewspaper: null });
		this.setStateCustom({ newspaperList: newspaperByDepartment });
	}

	private onSelectedNewspaper(newspaper: NewspaperInterface): void
	{
		this.setFormData({ selectedNewspaper: newspaper });
	}

	private onChangePublishDate(date: Date | null): void
	{
		this.setFormDataLegalNotice({ publishDate: date });
	}

	private onForcePublishDate(date: Date | null, isForcePublishDate: boolean): void
	{
		this.setFormDataLegalNotice({
			publishDate: date,
			isForcePublishDate
		});
	}

	private onChangeNumberOfCopies(numberOfCopies: number): void
	{
		this.setFormDataLegalNotice({ numberOfCopies });
	}

	private onChangeReference(reference: string): void
	{
		this.setFormDataLegalNotice({ reference });
	}

	//</editor-fold>

	//<editor-fold desc="Block Category methods" defaultstate="collapsed">

	private onSelectedPrimaryCategory(category: FormBuilderCategoryInterface): void
	{
		this.setFormData({ selectedPrimaryCategory: category, selectedSecondaryCategory: null });
		this.setFormDataLegalNoticeOption({ isBodacc: false });
	}

	private onSelectedSecondaryCategory(category: FormBuilderCategoryInterface): void
	{
		const isCategoryFiltered = LegalNoticeTypeEnum.filterWithoutSiren.some(
			filteredCategory => filteredCategory.value === category.categoryType
		);

		// If category is on filter without siren
		if (isCategoryFiltered) {
			this.setFormData({
				selectedSecondaryCategory: category,
				billingAddress: this.state.formData.selectedClient?.address || null,
			});

			this.setFormDataLegalNotice({
				numberOfCopies: this.state.formData.selectedClient?.options?.numberOfCopies,
				discount: this.state.isEdit
					? this.state.formData.legalNotice.discount
					: this.state.formData.selectedClient?.options?.discountGranted,
			});
		} else {
			if (this.state.formData.selectedClient) {
				const isDiscountPreferenceIsCreditReduced = this.state.formData.selectedClient.options.discountPreference?.toString()
					=== ClientDiscountPreferenceEnum.CREDIT_DEDUCTED.value;

				const defaultBilling = isDiscountPreferenceIsCreditReduced
					? 'CLIENT'
					: (this.state.formData.selectedClient.options?.billedTo === 'ORDER_GIVER' ? 'CLIENT' : 'CONSUMER');

				const billingAddress = (this.state.isEdit)
					? this.state.formData.billingAddress
					: isDiscountPreferenceIsCreditReduced
						? this.state.formData.selectedClient.address
						: (this.state.formData.selectedClient.options?.billedTo === 'ORDER_GIVER' || this.state.formData.selectedClient.options?.billedTo === 'CLIENT')
							? this.state.formData.selectedClient.address
							: this.state.formData.consumer?.address
				;

				this.setFormData({
					selectedSecondaryCategory: category,
					billingAddress: billingAddress,
					legalNotice: {
						...this.state.formData.legalNotice,
						numberOfCopies: this.state.formData.selectedClient.options?.numberOfCopies,
						discount: this.state.isEdit
							? this.state.formData.legalNotice.discount
							: this.state.formData.selectedClient.options?.discountGranted,
						configBillingPreference: {
							billing: defaultBilling,
							creditNote: 'CLIENT'
						}
					}
				});
			}
		}
	}

	private onResetBlockCategory(): void
	{
		this.setFormData({
			selectedPrimaryCategory: null,
			selectedSecondaryCategory: null,
			consumer: null,
			billingAddress: null,
		});
		this.setState({ isConsumerFormComplete: false });
	}

	//</editor-fold>

	//<editor-fold desc="Block Bodacc methods" defaultstate="collapsed">

	private onSelectedIsBodacc(event: any): void
	{
		this.setFormDataLegalNoticeOption({ isBodacc: event.target.checked });
	}

	//</editor-fold>

	//<editor-fold desc="Block Consumer methods" defaultstate="collapsed">

	/**
	 * Set state when the form is full
	 *
	 * @param isComplete
	 * @param data
	 * @private
	 */
	private onConsumerFormComplete(isComplete: boolean, data: any): void
	{
		const { isEdit, formData } = this.state;
		const isFilteredCategory = LegalNoticeTypeEnum.filterWithoutSiren.some(
			filteredCategory => filteredCategory.value === formData.selectedSecondaryCategory?.categoryType
		);

		const isDiscountPreferenceIsCreditReduced = formData.selectedClient?.options.discountPreference?.toString()
			=== ClientDiscountPreferenceEnum.CREDIT_DEDUCTED.value;

		const billingAddress = isFilteredCategory
			? formData.selectedClient?.address || null
			: (isEdit
					? formData.billingAddress
					: isDiscountPreferenceIsCreditReduced
						? formData.selectedClient?.address
						: (formData.selectedClient?.options.billedTo === 'ORDER_GIVER' || formData.selectedClient?.options.billedTo === 'CLIENT')
							? formData.selectedClient?.address
							: formData.consumer?.address
			);

		this.setState({ isConsumerFormComplete: isComplete });
		this.setFormData({
			billingAddress,
			consumer: data.formData,
		});
	}


	private updateConsumerData(clientSellsyId: number): void
	{
		this.setFormDataConsumer({ extSellsyId: clientSellsyId });
	}

	//</editor-fold>

	//<editor-fold desc="Block Billing Address methods" defaultstate="collapsed">

	private handleBillingAddressFromSellsy(addressData: AddressInterface): void
	{
		this.setFormData({ sellsyConsumerAddress: addressData }, () =>
		{
			this.prepareBillingAddress();
		});
	}

	private prepareBillingAddress(): void
	{
		const billingAddress = this.state.formData?.sellsyConsumerAddress!;
		this.setFormDataBillingAddress({
				name: billingAddress.name || '',
				street: billingAddress.street || '',
				number: billingAddress.number || 0,
				city: billingAddress.city || '',
				zipCode: billingAddress.zipCode || '',
				country: billingAddress.country || '',
				extSellsyId: Number(billingAddress.extSellsyId) || 0,
				phone: billingAddress.phone || '',
				isBillingAddress: true
			}
		);
	}

	private updateAddressData(addressSellsyId: number): void
	{
		this.setFormDataConsumerAddressExtSellsyId(addressSellsyId, () =>
		{
			this.setFormDataBillingAddress({ extSellsyId: addressSellsyId });
		});
	}

	//</editor-fold>

	//<editor-fold desc="Block Config billing methods" defaultstate="collapsed">

	private onChangeDiscount(event: any): void
	{
		this.setFormDataLegalNotice({ discount: parseInt(event.target.value) });
	}

	private onSelectedAffiliationBilling(configPreference: string): void
	{
		const selectedBilling = configPreference || 'CLIENT';

		this.setFormData({
			billingAddress:
				selectedBilling === 'CLIENT'
					? this.state.formData.selectedClient?.address
					: this.state.formData.consumer?.address
		});

		setTimeout(() =>
		{
			this.setFormDataLegalNotice({
				configBillingPreference: {
					...this.state.formData.legalNotice.configBillingPreference,
					billing: selectedBilling
				},
			});
		}, 0);
	}

	private onSelectedAffiliationCreditNote(configPreference: string): void
	{
		const selectedBilling = configPreference || 'CLIENT';
		this.setFormDataLegalNotice({
			configBillingPreference: {
				...this.state.formData.legalNotice.configBillingPreference,
				creditNote: selectedBilling
			}
		});
	}

	//</editor-fold>

	//<editor-fold desc="Block SendTo methods" defaultstate="collapsed">

	private onSendToSelected(selections: SendToSelections): void
	{
		this.setFormData({ sendTo: selections });
	}

	//</editor-fold>

	//<editor-fold desc="Block Content methods" defaultstate="collapsed">

	private onCheckContentHeaderIsHeader(event: any): void
	{
		// Update the isHeader state
		this.setFormDataLegalNoticeOption({ isHeader: event.target.checked });

		// If the checkbox is checked, remove the headerCharacterCount
		if (!event.target.checked) {
			this.setFormData({ headerCharacterCount: 0 });
		} else {
			// If unchecked, calculate and set the headerCharacterCount
			this.setFormData({ headerCharacterCount: this.legalNoticePriceService.countTempHeaderContent(this.state.formData.consumer) });
		}
	}

	private handleSelectedLogo(selectedLogo: any): void
	{
		this.setFormDataLegalNotice({
			logo: (selectedLogo.logoUrl) ? selectedLogo.logoUrl : ''
		});
	}

	private onCheckContentHeaderIsLogo(isLogo: any): void
	{
		if (isLogo === false) {
			this.setFormDataLegalNotice({
				logo: ''
			});
		}

		this.setFormDataLegalNoticeOption({ isLogo: isLogo });
	}

	private onChangeLegalNoticeTitle(title: string): void
	{
		this.setFormDataLegalNotice({ title });
	}

	private onChangeLegalNoticeContent(content: string): void
	{
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}

		// Configure un nouveau timeout
		this.timeoutId = setTimeout(() =>
		{
			try {
				const contentState = convertFromRaw(JSON.parse(content));
				const htmlContent = this.formatHtmlLegalNoticeContent(contentState);

				this.setFormDataLegalNotice({ content: htmlContent });
			} catch (error) {
				console.error('Invalid content format:', error);
			}
		}, 500);
	}

	private formatHtmlLegalNoticeContent(content: ContentState): string
	{
		const blocks = content.getBlocksAsArray();
		let html = '';

		blocks.forEach((block: any) =>
		{
			let blockHTML = '';
			let lastIndex = 0;
			let text = block.getText();

			block.findStyleRanges(
				(character: any) => character.getStyle().has('BOLD'),
				(start: number, end: number) =>
				{
					blockHTML += text.slice(lastIndex, start);
					blockHTML += `<b>${ text.slice(start, end) }</b>`;
					lastIndex = end;
				}
			);

			blockHTML += text.slice(lastIndex);

			switch (block.getType()) {
				case 'header-one':
					html += `<h1>${ blockHTML }</h1>`;
					break;
				case 'header-two':
					html += `<h2>${ blockHTML }</h2>`;
					break;
				case 'unstyled':
				default:
					html += `<p>${ blockHTML }</p>`;
					break;
			}
		});

		return html;
	}

	private onChangeLegalNoticeSignature(signature: string): void
	{
		this.setFormDataLegalNotice({ signature });
	}

	private isHeaderCharacterCount(count: number): void
	{
		this.setFormData({ headerCharacterCount: count });
	}

	//</editor-fold>

	//<editor-fold desc="Block New Name / New Address methods" defaultstate="collapsed">

	private onNewName(name: string): void
	{
		this.setFormDataChangeOfName(name, () =>
		{
			this.props.flashMessageContext.flashMessage(
				'Changement de nom',
				'Le changement de nom a été effectué avec succès, sur Sellsy et sur le formulaire',
				'success'
			);
		});
	}

	private onNewAddress(newAddress: AddressInterface): void
	{
		this.setFormDataChangeOfAddress(newAddress);
		this.setFormData({ sellsyConsumerAddress: newAddress }, () =>
		{
			this.props.flashMessageContext.flashMessage(
				'Changement d\'adresse',
				'Le changement d\'adresse a été effectué avec succès, sur Sellsy et sur le formulaire',
				'success'
			);
		});
	}

	//</editor-fold>

	//<editor-fold desc="State / Components methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		if (!this.state.isEdit) {
			this.initState();
		}

		this.departmentListRequest();
		this.formBuilderCategoryListRequest();
		await this.prepareComponentForEdit();
	}

	private async departmentListRequest(): Promise<void>
	{
		const departments: DepartmentInterface[] = await this.legalNoticeService.departmentList();
		this.setState({ departmentList: departments });
	}

	private async formBuilderCategoryListRequest(): Promise<void>
	{
		const categoryList: FormBuilderCategoryInterface[] = await this.legalNoticeService.categoryList();
		this.setState({ formBuilderCategoryList: categoryList });
	}

	private async formBuilderParentCategory(categoryId: number): Promise<FormBuilderCategoryInterface>
	{
		return await this.legalNoticeService.parentCategory(categoryId);
	}

	private async newspaperListRequest(department: DepartmentInterface, newspaperType: NewspaperTypeEnum | string): Promise<void>
	{
		const newspaperByDepartment = await this.legalNoticeService.newspaperList(
			this.props.authCompanyContext.authCompany.id,
			department,
			newspaperType
		);

		this.setStateCustom({ newspaperList: newspaperByDepartment });
		if (!this.state.formData.selectedNewspaper) {
			this.setFormData({ selectedNewspaper: null });
		}
	}

	private initState(): ViewState
	{
		return {
			errorMessage: null,
			departmentList: [],
			newspaperList: [],
			formBuilderCategoryList: [],
			isConsumerFormComplete: false,
			formData: {
				selectedClient: null,
				selectedCollaborator: null,
				selectedDepartment: null,
				selectedNewspaper: null,
				selectedPrimaryCategory: null,
				selectedSecondaryCategory: null,
				newspapers: [],
				consumer: null,
				billingAddress: null,
				sellsyConsumerAddress: null,
				changeOf: {
					name: null,
					address: null
				},
				headerCharacterCount: 0,
				sendTo: {
					certificate: [],
					credit: [],
					invoice: [],
					receipt: []
				},
				legalNotice: {
					id: null,
					tag: null,
					title: '',
					content: '',
					signature: '',
					logo: '',
					publishDate: null,
					isForcePublishDate: false,
					discount: 0,
					configBillingPreference: {
						billing: null,
						creditNote: null
					},
					numberOfCopies: 0,
					reference: null,
					status: null,
					paymentStatus: null,
					quoteStatus: null,
					option: {
						publishType: NewspaperTypeEnum.WEB,
						billingType: BilledToTypeEnum.ORDER_GIVER,
						isHeader: false,
						isLogo: false,
						isBodacc: false
					},
					extSupplierUid: null
				}
			},
			isEdit: false,
			isDataLoading: false,
			isCreateLoading: false,
			progressProcess: 0
		};
	}

	private setFormData(update: Partial<ViewState['formData']>, callback?: () => void): void
	{
		this.setState((prevState) => ({
			formData: {
				...prevState.formData,
				...update
			}
		}), callback);
	}

	private setStateCustom(update: Partial<ViewState>, callback?: () => void): void
	{
		this.setState((prevState) => ({
			...prevState,
			...update
		}), callback);
	}

	private setFormDataLegalNotice(
		update: Partial<ViewState['formData']['legalNotice']>,
		callback?: () => void
	): void
	{
		this.setState((prevState) => ({
			formData: {
				...prevState.formData,
				legalNotice: {
					...prevState.formData.legalNotice,
					...update
				}
			}
		}), callback);
	}

	private setFormDataLegalNoticeOption(
		update: Partial<ViewState['formData']['legalNotice']['option']>,
		callback?: () => void
	): void
	{
		this.setState((prevState) => ({
			formData: {
				...prevState.formData,
				legalNotice: {
					...prevState.formData.legalNotice,
					option: {
						...prevState.formData.legalNotice.option,
						...update
					}
				}
			}
		}), callback);
	}

	private setFormDataConsumer(update: Partial<ViewState['formData']['consumer']>, callback?: () => void): void
	{
		this.setState((prevState: any) => ({
			formData: {
				...prevState.formData,
				consumer: {
					...prevState.formData.consumer,
					...update
				}
			}
		}), callback);
	}

	private setFormDataChangeOfName(name: string, callback?: () => void): void
	{
		this.setState((prevState: any) => ({
			formData: {
				...prevState.formData,
				changeOf: {
					...prevState.formData.changeOf,
					name: name
				}
			}
		}), callback);
	}

	private setFormDataChangeOfAddress(address: AddressInterface, callback?: () => void): void
	{
		this.setState((prevState: any) => ({
			formData: {
				...prevState.formData,
				changeOf: {
					...prevState.formData.changeOf,
					address: address
				}
			}
		}), callback);
	}

	private setFormDataConsumerAddressExtSellsyId(extSellsyId: number, callback?: () => void): void
	{
		this.setState((prevState: any) => ({
			formData: {
				...prevState.formData,
				consumer: {
					...prevState.formData.consumer,
					address: {
						...prevState.formData.consumer.address,
						extSellsyId: extSellsyId
					}
				}
			}
		}), callback);
	}


	private setFormDataBillingAddress(update: Partial<ViewState['formData']['billingAddress']>, callback?: () => void): void
	{
		this.setState((prevState: any) => ({
			formData: {
				...prevState.formData,
				billingAddress: {
					...prevState.formData.billingAddress,
					...update
				}
			}
		}), callback);
	}

	//</editor-fold>

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private validateLegalNotice(event: any): void
	{
		event.stopPropagation();
		const formData = this.state.formData;

		if (formData.legalNotice.configBillingPreference.billing === 'CONSUMER') {
			if (!formData.billingAddress?.extSellsyId) {
				window.alert('Merci de remplir le client à facturer dans le block sellsy');
				return;
			}
		}

		this.props.modalContext.content(
			`Récapitulatif : ${ formData.selectedPrimaryCategory?.label } - ${ formData.selectedSecondaryCategory?.label }`,
			<LegalNoticeModal
				modalContext={ this.props.modalContext }
				legalNotice={ formData.legalNotice }
				isHeaderCharacterCount={ this.state.formData.headerCharacterCount }
				selectedClient={ formData.selectedClient as ClientInterface }
				selectedDepartment={ formData.selectedDepartment as DepartmentInterface }
				selectedNewspaper={ formData.selectedNewspaper as NewspaperInterface }
				consumer={ formData.consumer as ConsumerDataInterface }
				billingAddress={ formData.billingAddress as AddressInterface }
				sendTo={ formData.sendTo }
				selectedPrimaryCategory={ formData.selectedPrimaryCategory as FormBuilderCategoryInterface }
				selectedSecondaryCategory={ formData.selectedSecondaryCategory as FormBuilderCategoryInterface }
				onCreate={ this.onCreate.bind(this) }
			/>
		);
	}

	private isFormComplete(): boolean
	{
		const { formData } = this.state;
		const {
			legalNotice,
			selectedClient,
			selectedCollaborator,
			selectedDepartment,
			selectedNewspaper,
			selectedPrimaryCategory,
			selectedSecondaryCategory,
			billingAddress,
			consumer,
			sendTo
		} = formData;

		// Add checks for all required fields
		const isLegalNoticeComplete = legalNotice.content && legalNotice.content !== '<p></p>' && legalNotice.publishDate;
		const isClientComplete = selectedClient !== null;
		const isCollaboratorComplete = selectedCollaborator !== null;
		const isDepartmentComplete = selectedDepartment !== null;
		const isNewspaperComplete = selectedNewspaper !== null;
		const isCategoryComplete = selectedPrimaryCategory !== null && selectedSecondaryCategory !== null;
		const isBillingAddressComplete = billingAddress !== null;
		const isSendToComplete = (sendTo) ? Object.keys(sendTo).length > 0 : [];
		const isConsumerComplete = consumer !== null;

		return Boolean(
			isLegalNoticeComplete
			&& isClientComplete
			&& isCollaboratorComplete
			&& isDepartmentComplete
			&& isNewspaperComplete
			&& isCategoryComplete
			&& isConsumerComplete
			&& isBillingAddressComplete
			&& isSendToComplete
		);
	}

	private async onCreate(type: string): Promise<void>
	{

		//loading
		this.setState({ isCreateLoading: true });

		try {

			// Call API for creating or editing legal notice
			const legalNoticeData = await this.prepareFormData(type);
			const response: any = await this.state.isEdit && this.state.formData.legalNotice.id
				? await this.legalNoticeService.edit(this.state.formData.legalNotice.id, legalNoticeData)
				: await this.legalNoticeService.create(legalNoticeData);

			if (response.errorMessage) {
				throw new Error(response.errorMessage);
			}

			const legalNoticeId = response.id || this.state.formData.legalNotice.id;

			// Execute additional file service calls in background
			this.handleFileServiceCalls(type, legalNoticeId, legalNoticeData);

			//loading
			this.setState({ isCreateLoading: false });

			// Flash message
			this.props.flashMessageContext.flashMessage(
				'Mise à jour réussie',
				`L'annonce légale a bien été ${ this.state.isEdit ? 'éditée' : 'créée' } avec succès`,
				'success'
			);

			// Navigate
			this.props.navigation(`/admin/legal-notices/${legalNoticeId}`);
		} catch (error) {

			//loading
			this.setState({ isCreateLoading: false });

			this.props.flashMessageContext.flashMessage(
				'Erreur',
				`Une erreur est survenue lors de la ${ this.state.isEdit ? 'modification' : 'création' } de l'annonce légale.`,
				'error'
			);
		}
	}

	// New method to handle file service calls
	private async handleFileServiceCalls(type: string, legalNoticeId: number, legalNoticeData: any): Promise<void>
	{
		if (type === PublishStateEnum.QUOTE.value) {
			if (this.state.isEdit) {
				this.apiLegalNoticeFileService.editEstimateFile(legalNoticeId, legalNoticeData).catch(console.error);
			} else {
				this.apiLegalNoticeFileService.createEstimateFile(legalNoticeId, legalNoticeData).catch(console.error);
			}
		} else if (type === PublishStateEnum.PLANNED.value) {
			if (this.state.isEdit) {
				this.apiLegalNoticeFileService.editBillingFile(legalNoticeId, legalNoticeData)
					.then(() =>
					{
						if (legalNoticeData.legalNotice.discount > 0) {
							this.apiLegalNoticeFileService.editCreditNoteFile(legalNoticeId, legalNoticeData).catch(console.error);
						}
					})
					.catch(console.error);

				// Set pending notification
				this.props.notificationContext.setLegalNoticeFilePendingResponse({
					legalNoticeId: legalNoticeId,
					legalNoticeFile: LegalNoticeFileTypeEnum.CERTIFICATE.value,
					isLoading: true,
				});

				this.apiLegalNoticeFileService.editCertificateFile(legalNoticeId, legalNoticeData)
					.then(() =>
					{
						this.props.notificationContext.setLegalNoticeFilePendingResponse({
							legalNoticeId: legalNoticeId,
							legalNoticeFile: LegalNoticeFileTypeEnum.CERTIFICATE.value,
							isLoading: false,
						});

						// Send Notification
						this.props.notificationContext.notification(
							'Nouveau document',
							`Annonce légale (${ legalNoticeData.legalNotice.tag }) - Votre <span style="font-weight: 500">${ LegalNoticeFileTypeEnum.CERTIFICATE.label }</span> pour <span style="font-weight: 500">${ legalNoticeData.billingAddress.name }</span> a bien été créé`,
							'success',
							`/admin/legal-notices/${ legalNoticeId }`
						);
					})
					.catch((error) =>
					{
						// Send failure notification
						this.props.notificationContext.notification(
							'Erreur lors de la création du document',
							`Annonce légale (${ legalNoticeData.legalNotice.tag }) - Une erreur est survenue lors de la création de <span style="font-weight: 500">${ LegalNoticeFileTypeEnum.CERTIFICATE.label }</span> pour <span style="font-weight: 500">${ legalNoticeData.billingAddress.name }</span>. Veuillez réessayer.`,
							'error',
							`/admin/legal-notices/${ legalNoticeId }`
						);
						// Log the error to the console
						console.error(error);
					});
			} else {

				this.apiLegalNoticeFileService.createBillingFile(legalNoticeId, legalNoticeData).catch(console.error)
					.then(() =>
					{
						if (legalNoticeData.legalNotice.discount > 0) {
							return this.apiLegalNoticeFileService.createCreditNoteFile(legalNoticeId, legalNoticeData);
						}
					})
					.catch(console.error)
				;
				this.apiLegalNoticeFileService.createCertificateFile(legalNoticeId, legalNoticeData)
					.then(() =>
					{
						this.props.notificationContext.setLegalNoticeFilePendingResponse({
							legalNoticeId: legalNoticeId,
							legalNoticeFile: LegalNoticeFileTypeEnum.CERTIFICATE.value,
							isLoading: false,
						});

						// Send Notification
						this.props.notificationContext.notification(
							'Nouveau document',
							`Annonce légale (${ legalNoticeData.legalNotice.tag }) - Votre <span style="font-weight: 500">${ LegalNoticeFileTypeEnum.CERTIFICATE.label }</span> pour <span style="font-weight: 500">${ legalNoticeData.billingAddress.name }</span> a bien été créé`,
							'success',
							`/admin/legal-notices/${ legalNoticeId }`
						);

					})
					.catch(console.error);
			}
		}
	}

	private async prepareFormData(type: string): Promise<any>
	{
		const formData = this.state.formData;

		return {
			departmentId: formData.selectedDepartment?.id,
			newspaperId: formData.selectedNewspaper?.id,
			clientId: formData.selectedClient?.id,
			collaboratorId: formData.selectedCollaborator?.id,
			categoryId: formData.selectedSecondaryCategory?.id,
			legalNotice: {
				...formData.legalNotice,
				price: await this.calculatedPrice(),
				nbCharacters: await this.nbCharacters(),
				status: PublishStateEnum.findByValue(type)?.value,
				paymentStatus: (formData.legalNotice.paymentStatus) ? formData.legalNotice.paymentStatus?.value : PaymentStateEnum.PENDING.value,
				quoteStatus: (type === PublishStateEnum.QUOTE.value) ? QuoteStatusEnum.PENDING.value : null,
				discount: formData.legalNotice.discount,
				configBillingPreference: {
					billing: (formData.legalNotice.configBillingPreference.billing) ? formData.legalNotice.configBillingPreference.billing : 'CLIENT',
					creditNote: (formData.legalNotice.configBillingPreference.creditNote) ? formData.legalNotice.configBillingPreference.creditNote : 'CLIENT'
				},
				option: {
					...formData.legalNotice.option,
					publishType: typeof formData.legalNotice.option.publishType === 'string'
						? formData.legalNotice.option.publishType
						: formData.legalNotice.option.publishType.value,
					billingType: formData.legalNotice.option.billingType.value,
					numberOfCopies: formData.legalNotice.numberOfCopies,
				}
			},
			billingAddress: formData.billingAddress,
			sendTo: formData.sendTo,
			consumer: formData.consumer,
			status: PublishStateEnum.findByValue(type)?.value
		};
	}

	private async calculatedPrice(): Promise<number>
	{
		const VAT_RATE = 0.2;
		const PRINT_VAT_RATE = 0.021;

		const isFixedPrice: boolean = this.legalNoticePriceService.isFixedPrice(
			this.state.formData.selectedDepartment,
			this.state.formData.selectedSecondaryCategory
		);

		// Calculate the annex price
		const annexPrice = parseFloat(
			this.legalNoticePriceService.getAnnexPrice(
				this.state.formData.selectedDepartment as DepartmentInterface,
				this.state.formData.selectedSecondaryCategory as FormBuilderCategoryInterface,
				isFixedPrice
			).toFixed(2)
		);

		const getPrintPrice = await this.handlePrintPrice();
		const getShippingCostPrice = await this.legalNoticePriceService.getShippingCostPrice(
			this.state.formData.legalNotice.numberOfCopies
		);
		const getExternPrice = await this.legalNoticePriceService.getExternPrice();
		const getBodaccPrice = await this.legalNoticePriceService.getBodacPrice();

		// Calculate number of characters
		const nbCharacters = isFixedPrice
			? 1
			: (
				stripHtmlTags(this.state.formData.legalNotice.content).length +
				stripHtmlTags(this.state.formData.legalNotice.title).length +
				stripHtmlTags(this.state.formData.legalNotice.signature).length +
				(this.state.formData.headerCharacterCount || 0)
			);

		const legalNoticePrice = annexPrice * nbCharacters;

		const printPrice: number = (this.state.formData.legalNotice?.option?.publishType === NewspaperTypeEnum.PAPER.value && this.state.formData.legalNotice.numberOfCopies !== 0)
			? getPrintPrice * this.state.formData.legalNotice.numberOfCopies
			: 0;

		const shippingCostPrice = (this.state.formData.legalNotice.option.publishType === NewspaperTypeEnum.PAPER.value && this.state.formData.legalNotice.numberOfCopies !== 0)
			? getShippingCostPrice
			: 0;

		const bodaccPrice = this.state.formData.legalNotice.option.isBodacc
			? getBodaccPrice
			: 0;

		const externPrice = (
				(this.state.formData.selectedNewspaper?.editorial && this.state.formData.selectedNewspaper.editorial !== 'heraultjuridique') &&
				(this.state.formData.selectedNewspaper?.name && this.state.formData.selectedNewspaper.name !== 'L\'Echo du Languedoc')
			) ? (LocalStorageService.getAuthCompanyId() !== 4) ? getExternPrice : 0
				: 0
		;

		// Calculate VAT-inclusive prices
		const legalNoticePriceIncVat = parseFloat((legalNoticePrice * (1 + VAT_RATE)).toFixed(2));
		const printPriceIncVat = parseFloat((printPrice * (1 + PRINT_VAT_RATE)).toFixed(2));
		const shippingCostPriceIncVat = parseFloat((shippingCostPrice * (1 + VAT_RATE)).toFixed(2));
		const bodaccPriceIncVat = parseFloat((bodaccPrice * (1 + VAT_RATE)).toFixed(2));
		const externPriceIncVat = parseFloat((externPrice * (1 + VAT_RATE)).toFixed(2));

		// Total calculation
		return parseFloat((legalNoticePriceIncVat + printPriceIncVat + shippingCostPriceIncVat + bodaccPriceIncVat + externPriceIncVat).toFixed(2));
	}

	private async nbCharacters(): Promise<number>
	{
		const isFixedPrice: boolean = this.legalNoticePriceService.isFixedPrice(
			this.state.formData.selectedDepartment,
			this.state.formData.selectedSecondaryCategory
		);

		let totalCharacters =
			stripHtmlTags(this.state.formData.legalNotice.content).length +
			stripHtmlTags(this.state.formData.legalNotice.title).length +
			stripHtmlTags(this.state.formData.legalNotice.signature).length;

		if (this.state.formData.legalNotice.option.isHeader) {
			totalCharacters += this.state.formData.headerCharacterCount || 0;
		} else {
			totalCharacters -= this.state.formData.headerCharacterCount || 0;
		}

		return isFixedPrice ? 1 : totalCharacters;
	}

	private async prepareComponentForEdit(isFromButtonActions: boolean = false): Promise<void>
	{
		if (isFromButtonActions) {
			const result = await Swal.fire({
				title: 'Voulez vous revenir à l\'état inital ?',
				text: 'Attention vous allez perdre vos modifications en cours',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Oui, revenir aux données par défaut!'
			});

			if (!result.isConfirmed) {
				return;
			}
		}

		await this.setStateForEdit();
	}

	private async setStateForEdit(): Promise<void>
	{
		const queryParams = new URLSearchParams(this.props.location.search);
		const legalNoticeId = queryParams.get('legalNoticeId');

		if (legalNoticeId) {
			// Set state for isLoading
			this.setState({ isEdit: true, isDataLoading: true });
			const getLegalNoticeFormApi = await this.apiLegalNoticeService.show(parseInt(legalNoticeId));

			const getParentCategory = await this.formBuilderParentCategory(
				getLegalNoticeFormApi.formBuilderCategory.parent?.id
			);

			const isDiscountPreferenceIsCreditReduced = getLegalNoticeFormApi.client.options.discountPreference.toString()
				=== ClientDiscountPreferenceEnum.CREDIT_DEDUCTED.value
			;

			// Build list of newspapers
			this.newspaperListRequest(getLegalNoticeFormApi.publishDepartment, getLegalNoticeFormApi.option.publishType);

			if (getLegalNoticeFormApi) {
				this.setFormData({
					selectedClient: getLegalNoticeFormApi.client,
					selectedCollaborator: getLegalNoticeFormApi.collaborator,
					selectedDepartment: getLegalNoticeFormApi.publishDepartment,
					selectedNewspaper: getLegalNoticeFormApi.newspaper,
					selectedPrimaryCategory: getParentCategory,
					selectedSecondaryCategory: getLegalNoticeFormApi.formBuilderCategory,
					consumer: getLegalNoticeFormApi.consumer,
					billingAddress: (getLegalNoticeFormApi.configBillingPreference === 'CLIENT')
						? getLegalNoticeFormApi.client.address
						: getLegalNoticeFormApi.consumer.address
					,
					sendTo: this.handleSendToForEdit(getLegalNoticeFormApi),
					headerCharacterCount: (getLegalNoticeFormApi.option.isHeader)
						? this.legalNoticePriceService.countTempHeaderContent(getLegalNoticeFormApi.consumer)
						: 0
					,
					legalNotice: {
						id: getLegalNoticeFormApi.id,
						tag: getLegalNoticeFormApi.tag,
						title: getLegalNoticeFormApi.title,
						content: getLegalNoticeFormApi.content,
						signature: getLegalNoticeFormApi.signature,
						logo: getLegalNoticeFormApi.logo,
						publishDate: new Date(getLegalNoticeFormApi.publishDate),
						isForcePublishDate: getLegalNoticeFormApi.isForcePublishDate,
						discount: getLegalNoticeFormApi.discount,
						configBillingPreference: {
							billing: (isDiscountPreferenceIsCreditReduced) ? 'CLIENT' : getLegalNoticeFormApi.configBillingPreference,
							creditNote: getLegalNoticeFormApi.configCreditNotePreference
						},
						numberOfCopies: getLegalNoticeFormApi.numberOfCopies,
						reference: getLegalNoticeFormApi.reference,
						status: PublishStateEnum.findByValue(getLegalNoticeFormApi.status) as PublishStateEnum,
						paymentStatus: PaymentStateEnum.findByValue(getLegalNoticeFormApi.paymentStatus) as PaymentStateEnum,
						quoteStatus: QuoteStatusEnum.findByValue(getLegalNoticeFormApi.quoteStatus) as QuoteStatusEnum,
						option: {
							publishType: getLegalNoticeFormApi.option.publishType,
							billingType: BilledToTypeEnum.findByValue(getLegalNoticeFormApi.option.billingType),
							isHeader: getLegalNoticeFormApi.option.isHeader,
							isLogo: getLegalNoticeFormApi.option.isLogo,
							isBodacc: getLegalNoticeFormApi.option.isBodacc
						},
						extSupplierUid: getLegalNoticeFormApi.extSupplierUid
					}
				}, () =>
				{
					// Handle is loading
					this.setState({ isDataLoading: false });
				});
			}
		}
	}

	private handleSendToForEdit(getLegalNoticeFormApi: LegalNoticeInterface): any
	{
		const sendTo = getLegalNoticeFormApi.sendTo || {};
		if (this.state.isEdit) {
			return sendTo;
		} else {
			const clientSendTo = getLegalNoticeFormApi.client?.options?.sendTo || {};

			const mergeArrays = (arr1: string[], arr2: string[]) => Array.from(new Set([...arr1, ...arr2]));

			return {
				certificate: mergeArrays(sendTo.certificate || [], clientSendTo.certificate || []),
				credit: mergeArrays(sendTo.credit || [], clientSendTo.credit || []),
				invoice: mergeArrays(sendTo.invoice || [], clientSendTo.invoice || []),
				receipt: mergeArrays(sendTo.receipt || [], clientSendTo.receipt || [])
			};
		}
	}

	private mergeSendTo(clientSendTo: any, currentSendTo: any): any
	{
		const mergeArrays = (arr1: string[], arr2: string[]) => Array.from(new Set([...arr1, ...arr2]));

		return {
			certificate: mergeArrays(clientSendTo?.certificate || [], currentSendTo?.certificate || []),
			credit: mergeArrays(clientSendTo?.credit || [], currentSendTo?.credit || []),
			invoice: mergeArrays(clientSendTo?.invoice || [], currentSendTo?.invoice || []),
			receipt: mergeArrays(clientSendTo?.receipt || [], currentSendTo?.receipt || [])
		};
	}

	private async handlePrintPrice(): Promise<number>
	{
		if (this.state.formData.selectedNewspaper?.editorial && this.state.formData.selectedNewspaper.editorial !== 'heraultjuridique') {
			const getNewspaper = await this.apiPublicService.getNewspaper(
				this.state.formData.selectedNewspaper.id,
			);

			return getNewspaper.receiptPrice;
		} else {
			return await this.legalNoticePriceService.getPrintPrice();
		}
	}

	/**
	 * NOT USE FOR NOW ! KEEP IT PLZ
	 *
	 * @param legalNoticeContent
	 * @private
	 */
	private extractDataFromPasteLegalNotice(
		legalNoticeContent: string
	): ExtractedLegalNoticeDataInterface
	{
		let extractedData: ExtractedLegalNoticeDataInterface = {
			capital: null,
			rcs: null,
			address: {
				street: null,
				number: null,
				city: null,
			},
		};

		// Extract capital
		const capitalRegex: RegExp = /capital[^0-9]*([\d., ]+)/i;
		const capitalMatch: RegExpMatchArray | null = legalNoticeContent.match(capitalRegex);
		if (capitalMatch) {
			extractedData.capital = capitalMatch[1];
		}

		// Extract RCS
		const rcsRegex: RegExp = /RCS\s+([A-Z]+(?:-|\s)?[A-Z]*)/i;
		const rcsMatch: RegExpMatchArray | null = legalNoticeContent.match(rcsRegex);
		if (rcsMatch) {
			extractedData.rcs = rcsMatch[1];
		}

		// Extract address
		const addressRegex: RegExp = /(siège\s+(social\s+)?(?:au\s+)?[:-]?\s*([^,]+?),\s*([\d\s]+)[,]?\s*(\d{5})\s+([A-ZÀ-Ÿ]+(?:\s+[A-ZÀ-Ÿ]+)*))/i;
		const addressMatch: RegExpMatchArray | null = legalNoticeContent.match(addressRegex);
		if (addressMatch) {
			extractedData.address = {
				street: addressMatch[3] ? addressMatch[3].trim() : null,
				number: addressMatch[4] ? addressMatch[4].trim() : null,
				city: addressMatch[6] ? addressMatch[6].trim() : null,
			};
		}

		return extractedData;
	}

	//</editor-fold>
}