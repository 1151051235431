import React, { Component, createRef, ReactElement } from 'react';
import {
	NewspaperClosureManagementProps,
	NewspaperClosureManagementState
} from './NewspaperClosureManagement.interface';
import { NewspaperClosureManagementService } from './NewspaperClosureManagement.service';
import HeroSection from '@/Modules/App/Components/HeroSection/HeroSection';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import Table from '@/Modules/App/Components/Library/Table/Table';
import LoaderFullPage from '@/Modules/App/Components/Loader/LoaderFullPage/LoaderFullPage';
import { ContentStyles } from '@/Modules/App/Components/Content/Content.styles';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import SectionCollapsed from '@/Modules/App/Components/Sections/SectionCollapsed/SectionCollapsed';
import LineDetailsRender from '@/Modules/App/Components/Library/LineDetailsRender/LineDetailsRender';
import Tag from '@/Modules/App/Components/Library/Tag/Tag';
import Input from '@/Modules/App/Components/Library/Input/Input';
import { dateFormat, dateToStr, formatDateTimeToInputValue } from '@/Utils/DateUtils';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { UserService } from '@/Modules/App/Services/User/User.service';

class NewspaperClosureManagement extends Component<NewspaperClosureManagementProps, NewspaperClosureManagementState>
{
	private newspaperClosureManagementService = new NewspaperClosureManagementService();
	private sidebarWidthRef = createRef<HTMLDivElement>();

	constructor(props: NewspaperClosureManagementProps)
	{
		super(props);

		// Config service
		this.newspaperClosureManagementService.setProps(this.props);
		this.newspaperClosureManagementService.subscribeState(this);

		// State
		this.state = this.newspaperClosureManagementService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.newspaperClosureManagementService.init();

		if (this.sidebarWidthRef.current) {
			const sidebarWidth = this.sidebarWidthRef.current.offsetWidth;
			this.setState({
				css: {
					contentWidth: sidebarWidth,
				}
			});
		}
	}

	componentWillUnmount(): void
	{
		this.newspaperClosureManagementService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const {
			editionNumber,
			newspaper,
			status,
			publishDate,
			depositedAt,
			documentPath,
		} = this.state.newspaperClosureManagement;

		if (this.state.isLoading) {
			return <LoaderFullPage/>;
		}

		return (
			<>
				<HeroSection
					title="Bouclage du journal"
					icon={ null }
				/>

				<div style={{ display: 'flex', gap: 10 }} ref={ this.sidebarWidthRef }>
					<div style={ {
						display: 'flex',
						flexDirection: 'column',
						gap: 50,
						paddingBottom: 100,
						width: `calc(${ this.state.css.contentWidth }px - 420px)`
					} }>
						<SectionCollapsed
							title={ `Annonces légales — clotûre du: ${ dateFormat(publishDate) }` }
							highlightColor={ colors.gray100 } isCollapsed={ false }
						>
							<Table
								columns={ this.newspaperClosureManagementService.buildColumnHeader() }
								list={ this.newspaperClosureManagementService.legalNoticeList(this.state) }
								filterParams={ null }
								onOrderBy={ () => null }
								onRowClick={ (event: any, item: any) => this.props.navigation('/admin/legal-notices/' + event.id + '?&from=' + window.location.pathname) }
							/>
						</SectionCollapsed>
					</div>
					<div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
						<div style={ ContentStyles.sidebar_block_title }>Détails</div>

						<LineDetailsRender info={ 'Numéro d’édition' } formData={ editionNumber }/>
						<LineDetailsRender info={ 'Journal' } formData={ newspaper.name }/>
						<LineDetailsRender info={ 'Statut' } formData={ null }
															 render={ <Tag value={ status } enumName={ 'PublishStateEnum' }/> }/>
						<LineDetailsRender info={ 'Date de parution' } formData={ dateFormat(publishDate) }/>
						<LineDetailsRender info={ 'Justificatif à envoyer' }
															 formData={ this.newspaperClosureManagementService.calculateTotalNumberOfCopies(this.state) }/>

						{ UserService.isSuperAdmin() &&
              <>
                <div style={ { ...ContentStyles.sidebar_block_title, marginTop: 15, } }>Bouclage</div>
                <div style={ { display: 'flex', flexDirection: 'column', gap: 20 } }>
                  <Input
                    type={ 'datetime-local' }
                    label={ 'Date et heure de bouclage' }
                    textHelp={ 'Correspond à la date et heure limite de réception des annonces légales avant la publication' }
                    name={ 'closure-datetime' }
                    value={
											this.state.newspaperClosureManagement.closedAt
												? formatDateTimeToInputValue(new Date(this.state.newspaperClosureManagement.closedAt))
												: this.state.closureDate
													? formatDateTimeToInputValue(new Date(this.state.closureDate))
													: ''
										}
                    onChange={ (event: any) => this.setState({
											newspaperClosureManagement: {
												...this.state.newspaperClosureManagement,
												closedAt: event.target.value
											}
										}) }
                  />

                  <div>
                    <Input
                      type={ 'text' }
                      label={ 'Journal (justificatif)' }
                      textHelp={ 'Veuillez ajouter le lien de téléchargement du journal (à retrouver sur le calaméo)' }
                      name={ 'depositAt' }
                      value={ documentPath || '' }
                      onChange={ (event: any) => this.setState(prevState => ({
												newspaperClosureManagement: {
													...prevState.newspaperClosureManagement,
													documentPath: event.target.value
												}
											})) }
                    />
										{ (this.state.errorMessage) &&
                      <div style={ { color: colors.error600, fontStyle: 'italic' } }> { this.state.errorMessage } </div>
										}
                  </div>

                  <Input
                    type={ 'date' }
                    label={ 'Date de dépôt' }
                    textHelp={ 'Veuillez remplir ce champ pour clôturer le journal et générer les feuilles de route' }
                    name={ 'depositAt' }
                    value={ depositedAt ? dateToStr(new Date(depositedAt)) : '' }
                    onChange={ (event: any) => this.setState({
											newspaperClosureManagement: {
												...this.state.newspaperClosureManagement,
												depositedAt: event.target.value
											}
										}) }
                  />

                  <div style={ {
										display: 'flex',
										flexDirection: 'column',
										gap: 5,
										padding: 0,
										justifyContent: 'space-between'
									} }>
                    <div style={ { display: 'flex', gap: 5 } }>
											{ this.state.newspaperClosureManagement &&
                        <Button
                          label={ 'Export XML' }
                          variant={ 'success' }
                          onClick={ (event: any) => this.newspaperClosureManagementService.exportXmlZip(this.state.newspaperClosureManagement.id as number) }
                        />
											}

                      <Button
                        label={ !(depositedAt) ? 'Mettre à jour' : 'Clôturer' }
                        variant={ 'primary' }
                        onClick={ (event: any) => this.newspaperClosureManagementService.handleEdit(event, this.state) }
                      />
                    </div>

                    <Button
                      label={ 'Générer les feuilles de routes' }
                      variant={ 'dark' }
                      disabled={ Boolean(!depositedAt) }
                      onClick={ () => this.newspaperClosureManagementService.handleGeneratePdf(this.state) }
                    />
                  </div>
                </div>
              </>
						}
					</div>
				</div>
			</>
		);
	}
}

export default withGlobalContext(NewspaperClosureManagement);