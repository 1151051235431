import React, { ReactElement, } from 'react';
import BreadcrumbsWrapper from '@/Modules/App/Wrapper/BreadcrumbsWrapper';
import { HeroSectionProps } from '@/Modules/App/Components/HeroSection/HeroSection.interface';
import { HeroSectionStyles } from '@/Modules/App/Components/HeroSection/HeroSection.styles';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { UserService } from '@/Modules/App/Services/User/User.service';

export default class HeroSection extends React.Component<HeroSectionProps, any>
{
	render(): ReactElement
	{
		const { button } = this.props;
		return (
			<>
				<div style={ HeroSectionStyles.base }>
					<div style={ UserService.isDeveloper() ? HeroSectionStyles.devContainer : HeroSectionStyles.container }>
						<BreadcrumbsWrapper/>
						<div style={ HeroSectionStyles.blockTitle }>
							<div style={ UserService.isDeveloper() ? HeroSectionStyles.devTitle : HeroSectionStyles.title }>
								{ (this.props.title) ? this.props.title : this.props.extendTitle }
							</div>
							{ this.props.button &&
                <Button
                  variant={ button?.variant! }
                  label={ button?.label! }
                  iconName={ button?.iconName! }
                  onClick={ button?.onClick! }
                />
							}

							{ this.props.buttonElement &&
								this.props.buttonElement
							}
						</div>
					</div>
				</div>
			</>
		);
	}
}