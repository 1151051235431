import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';
import { ClientModel } from '@/Modules/Client/Model/ClientModel';

export class ApiLogDeveloperService extends BaseService
{
  logUrl: string = `${ BaseService.API_URL }/v1/developer/logs`;

  async get(): Promise<any>
  {
    try {
      const response = await fetch(
        this.logUrl,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch logs');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching logs : ', error);
      throw error;
    }
  }

  async clear(): Promise<any>
  {
    try {
      const response = await fetch(
        this.logUrl + '/clear',
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
        }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch logs');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching logs : ', error);
      throw error;
    }
  }
}