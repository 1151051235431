import React, { Component, createRef, ReactElement } from 'react';
import { ClientDeveloperService } from './ClientDeveloper.service';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import LoaderFullPage from '@/Modules/App/Components/Loader/LoaderFullPage/LoaderFullPage';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { dateFormat } from '@/Utils/DateUtils';
import { ClientDeveloperProps, ClientDeveloperState } from '@/Modules/Client/Developer/Show/ClientDeveloper.interface';
import { UserService } from '@/Modules/App/Services/User/User.service';
import { SectionCollapsedStyles } from '@/Modules/App/Components/Sections/SectionCollapsed/SectionCollapsed.styles';
import Editable from '@/Modules/App/Components/Library/Editable/Editable';
import { UserDetailsStyles } from '@/Modules/User/Components/Blocks/UserDetails/UserDetails.styles';
import LineDetailsRender from '@/Modules/App/Components/Library/LineDetailsRender/LineDetailsRender';
import Tag from '@/Modules/App/Components/Library/Tag/Tag';
import { handleChangeInput } from '@/Utils/InputFormUtils';

class ClientDeveloper extends Component<ClientDeveloperProps, ClientDeveloperState>
{
	private clientService: ClientDeveloperService = new ClientDeveloperService();

	constructor(props: ClientDeveloperProps)
	{
		super(props);

		// Config service
		this.clientService.setProps(this.props);
		this.clientService.subscribeState(this);

		// State
		this.state = this.clientService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.clientService.init();
	}

	componentWillUnmount(): void
	{
		this.clientService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		// Init const
		const { isLoading, formData } = this.state;
		const { client } = this.props;
		const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			const { name, value } = event.target;

			if (name === 'companyId') {
				this.setState((prevState) => ({
					...prevState,
					formData: {
						...prevState.formData,
						company: {
							...prevState.formData.company,
							id: parseInt(value),
						},
					},
				}));
			} else {
				handleChangeInput(event, this.setState.bind(this));
			}
		};

		if (isLoading) {
			return <LoaderFullPage />;
		}

		return (
			<>
				<div style={ { lineHeight: 0.5, marginBottom: 20 } }>
					<h4 style={{ color: colors.white }}>
						{ client.name }
					</h4>
					<div style={ { fontSize: 11, color: colors.gray400 } }>ID : { client.id }</div>
				</div>

				{/* Reproduce the section collap style */}
				<div style={ SectionCollapsedStyles.container }>
					<div style={ SectionCollapsedStyles.heading(UserService.isDeveloper(), colors.blueRibbon50) }>
						<div style={ SectionCollapsedStyles.title }>
							Information Client
						</div>
					</div>
				</div>

				<Editable onEdit={ () => this.clientService.handleEdit(this.state) }>
					{ ({ isEditable }) => (
						<div style={ UserDetailsStyles.container }>
							<div style={ UserDetailsStyles.column }>
								<LineDetailsRender
									info={ 'Donneur d\'ordre depuis' }
									formData={ dateFormat(formData.createdAt) }
									isEditable={ isEditable }
								/>
								<LineDetailsRender
									info={ 'Nom' }
									formData={ formData.name }
									inputEdit={ { type: 'text', name: 'name', onChange } }
									isEditable={ isEditable }
								/>
								<LineDetailsRender
									info={ 'Siren' }
									formData={ formData.siren }
									inputEdit={ { type: 'text', name: 'siren', onChange } }
									isEditable={ isEditable }
								/>
								<LineDetailsRender
									info={ 'Siret' }
									formData={ (formData.siret) ? formData.siret : '-' }
									inputEdit={ { type: 'text', name: 'siret', onChange } }
									isEditable={ isEditable }
								/>
								<LineDetailsRender
									info={ 'Ape code' }
									formData={ (formData.apeCode) ? formData.apeCode : '-' }
									inputEdit={ { type: 'text', name: 'apeCode', onChange } }
									isEditable={ isEditable }
								/>
								<LineDetailsRender
									info={ 'Company ID' }
									formData={ formData.company?.id || '' }
									inputEdit={ { type: 'text', name: 'companyId', onChange } }
									isEditable={ isEditable }
								/>
								<LineDetailsRender
									info={ 'Sellsy ID' }
									formData={ formData.extSellsyId }
									inputEdit={ { type: 'text', name: 'extSellsyId', onChange } }
									isEditable={ isEditable }
								/>
								<LineDetailsRender
									info={ 'Statut' }
									render={ <Tag value={ formData.status.toString() } enumName={ 'ActiveStatusEnum' }/> }
									inputEdit={ { type: 'text', name: 'role', onChange }}
									formData={ formData.status.toString() }
									isEditable={ isEditable }
								/>
							</div>

							{/* Reproduce the section collap style */}
							<div style={{ ...SectionCollapsedStyles.container, marginTop: 20 }}>
								<div style={ SectionCollapsedStyles.heading(UserService.isDeveloper(), colors.blueRibbon50) }>
									<div style={ SectionCollapsedStyles.title }>
										Adresse
									</div>
								</div>
							</div>

							<div style={ UserDetailsStyles.column }>
								<LineDetailsRender
									info={ 'Numéro de rue' }
									inputEdit={ { type: 'text', name: 'address.number', onChange }}
									formData={ (formData.address?.number) ? formData.address?.number : '' }
									isEditable={ isEditable }
								/>
								<LineDetailsRender
									info={ 'Rue' }
									inputEdit={ { type: 'text', name: 'address.street', onChange }}
									formData={ (formData.address?.street) ? formData.address?.street : '' }
									isEditable={ isEditable }
								/>
								<LineDetailsRender
									info={ 'Complément' }
									inputEdit={ { type: 'text', name: 'address.additionalData', onChange }}
									formData={ (formData.address?.additionalData) ? formData.address?.additionalData : '' }
									isEditable={ isEditable }
								/>
								<LineDetailsRender
									info={ 'Code Postal' }
									inputEdit={ { type: 'text', name: 'address.zipCode', onChange }}
									formData={ (formData.address?.zipCode) ? formData.address?.zipCode : '' }
									isEditable={ isEditable }
								/>
								<LineDetailsRender
									info={ 'Ville' }
									inputEdit={ { type: 'text', name: 'address.city', onChange }}
									formData={ (formData.address?.city) ? formData.address?.city : '' }
									isEditable={ isEditable }
								/>
								<LineDetailsRender
									info={ 'Sellsy ID' }
									formData={ (formData.address?.extSellsyId) ? formData.address?.extSellsyId : '' }
									inputEdit={ { type: 'text', name: 'address.extSellsyId', onChange } }
									isEditable={ isEditable }
								/>
							</div>
						</div>
					) }
				</Editable>
			</>
		);
	}
}

export default withGlobalContext(ClientDeveloper);
