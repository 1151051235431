import React, { createRef, ReactElement } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { DashboardDeveloperService } from '@/Modules/Dashboard/Developer/DashboardDeveloper.service';
import LoaderFullPage from '@/Modules/App/Components/Loader/LoaderFullPage/LoaderFullPage';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { UserService } from '@/Modules/App/Services/User/User.service';

export default class DashboardDeveloper extends React.Component<
  any,
  { responseCallTemp: any | null; logs: string[]; isLoading: boolean; lastUpdated: Date | null }
>
{
  dashboardService: DashboardDeveloperService = new DashboardDeveloperService();
  logContainerRef = createRef<HTMLDivElement>();
  refreshInterval: NodeJS.Timeout | null = null;
  lastUpdatedInterval: NodeJS.Timeout | null = null;
  _isMounted: boolean = false;

  //<editor-fold desc="Ctor" defaultstate="collapsed">

  constructor(props: any) {
    super(props);

    this.state = {
      responseCallTemp: null,
      isLoading: true,
      lastUpdated: null,
      logs: [],
    };

    document.title = 'Publisur - DEVELOPER - Dashboard';
  }

  //</editor-fold>

  //<editor-fold desc="State methods" defaultstate="collapsed">

  async componentDidMount() {
    this._isMounted = true;

    this.dashboardService.subscribeState(this);
    await this.dashboardService.init();

    if (UserService.isDeveloper()) {
      this.refreshInterval = setInterval(async () => {
        if (this._isMounted) {
          this.setState({ isLoading: true });
          await this.dashboardService.refreshLogs();
          this.setState({ lastUpdated: new Date() }, () => {
            this.setState({ isLoading: false });
          });
        }
      }, 60000);
    }

    this.lastUpdatedInterval = setInterval(() => {
      if (this._isMounted) this.forceUpdate();
    }, 10000);
  }

  componentDidUpdate(prevProps: any, prevState: any)
  {
    if (prevState.logs.length !== this.state.logs.length) {
      if (this.logContainerRef.current) {
        this.logContainerRef.current.scrollTop = 0;
      }
    }
  }

  componentWillUnmount()
  {
    this._isMounted = false;

    if (this.refreshInterval) clearInterval(this.refreshInterval);
    if (this.lastUpdatedInterval) clearInterval(this.lastUpdatedInterval);
  }

  //</editor-fold>

  render(): ReactElement
  {
    if (this.state.isLoading) {
      return <LoaderFullPage />;
    }

    return (
      <div style={{ padding: '20px', color: colors.white }}>
        {/* HEADER */}
        <div style={{ textAlign: 'center', marginBottom: 20 }}>
          <h1 style={{ fontSize: '24px', fontWeight: 'bold' }}>👾 WELCOME DEVELOPER 👾</h1>
        </div>

        {/* CONTAINER LOGS */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2 style={{ color: colors.white, fontSize: '16px' }}>Logs système</h2>
          <div style={{ display: 'flex', gap: 20, marginBottom: 10 }}>
            <Button
              variant={ 'error' }
              label={ 'Clear logs' }
              onClick={ () => { this.dashboardService.clearLogs() } }
            />
            <p style={{ color: colors.gray400, fontSize: '12px', marginBottom: '10px' }}>
              Dernière mise à jour : { this.getLastUpdatedText() }
            </p>
          </div>
        </div>

        <div
          ref={ this.logContainerRef }
          style={{
            position: 'relative',
            border: `1px solid ${colors.gray700}`,
            borderRadius: '8px',
            background: colors.gray900,
            maxHeight: '60vh',
            overflow: 'scroll',
            overflowX: 'hidden',
          }}
        >
          {/* SCROLLABLE LOGS */}
          <div
            style={{
              overflowY: 'auto',
              overflowX: 'hidden',
              flex: 1,
              padding: '15px',
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              display: 'flex',
              flexDirection: 'column-reverse',
            }}
          >
            { this.state.logs.length === 0 ? (
              <p style={{ color: colors.gray400, textAlign: 'center' }}>Aucun log disponible</p>
            ) : (
              this.state.logs.map((log: string, index: number) => {
                const parsedLog = this.parseLog(log);
                if (!parsedLog) return null;

                return (
                  <div
                    key={index}
                    style={{
                      padding: '10px',
                      marginBottom: '8px',
                      background: colors.gray800,
                      borderRadius: '6px',
                    }}
                  >
                    <p style={{ fontSize: '12px', color: colors.gray400 }}>
                      <strong>{ parsedLog.date }</strong> - USER ID: { parsedLog.userId } - Niveau: { parsedLog.level }
                    </p>
                    <p style={{ fontSize: '14px', fontWeight: 'bold', color: colors.orange500 }}>
                      { parsedLog.message }
                    </p>
                    { parsedLog.exception && (
                      <pre
                        style={{
                          background: colors.gray700,
                          padding: '8px',
                          borderRadius: '4px',
                          fontSize: '12px',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        { JSON.stringify(parsedLog.exception, null, 2) }
                      </pre>
                    )}
                  </div>
                );
              })
            )}
          </div>

          <div style={{ padding: '10px', background: colors.gray900, display: 'flex', justifyContent: 'end' }}>
            <Button
              style={{
                background: colors.blueRibbon500,
                color: colors.white,
                padding: '12px 16px',
                cursor: 'pointer',
                fontSize: '14px',
                textAlign: 'center',
                boxShadow: '0px 4px 10px rgba(0,0,0,0.3)',
              }}
              variant={ 'primary' }
              label={ '' }
              iconName={ 'LuArrowUp' }
              onClick={ this.scrollToTop }
            />
          </div>
        </div>
      </div>
    );
  }

  //<editor-fold desc="Private methods" defaultstate="collapsed">

  scrollToTop = () => {
    if (this.logContainerRef.current) {
      this.logContainerRef.current.scrollTop = 0;
    }
  };

  parseLog(log: string)
  {
    const logRegex = /\[(.*?)\] \[(.*?)\] (\w+)\.(\w+):\s*((?:.|\n)*?)(\{.*\})?\s*$/s;

    const match = log.match(logRegex);
    if (!match) return null;

    const rawDate = new Date(match[1]);
    const formattedDate = rawDate.toLocaleString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    let exceptionText = null;
    if (match[6]) {
      exceptionText = match[6].trim();
    }

    return {
      date: formattedDate,
      userId: match[2] !== 'anonymous' ? match[2] : 'N/A',
      level: match[3],
      category: match[4],
      message: match[5].trim(),
      exception: exceptionText,
    };
  }

  getLastUpdatedText = (): string => {
    const { lastUpdated } = this.state;

    if (!lastUpdated) return "-";

    const now = new Date();
    const diffMs = now.getTime() - lastUpdated.getTime();
    const diffSec = Math.floor(diffMs / 1000);
    const diffMin = Math.floor(diffSec / 60);
    const diffH = Math.floor(diffMin / 60);

    if (diffSec < 10) return "À l'instant";
    if (diffSec < 60) return `Il y a ${diffSec} sec`;
    if (diffMin < 60) return `Il y a ${diffMin} min`;
    return `Il y a ${diffH} h`;
  };

  //</editor-fold>
}
